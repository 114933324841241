// import React, { useEffect, useState } from "react";
// import { Box, IconButton, Typography } from "@mui/material";
// import "./style.css";
// import { useNavigate } from "react-router-dom";

// export default function Customize() {
//   const navigate = useNavigate();
//   const [isSlider, setIsSlider] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isSliding, setIsSliding] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSlider(window.innerWidth <= 930);
//     };

//     window.addEventListener("resize", handleResize);

//     handleResize();

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const handleNext = () => {
//     if (isSliding) return;
//     setIsSliding(true);
//     setTimeout(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % 6);
//       setIsSliding(false);
//     }, 300);
//   };

//   const handlePrev = () => {
//     if (isSliding) return;

//     setIsSliding(true);
//     setTimeout(() => {
//       setCurrentIndex((prevIndex) => (prevIndex === 0 ? 6 - 1 : prevIndex - 1));

//       setIsSliding(false);
//     }, 300);
//   };

//   return (
//     <>
//       <Box className="customize_container">
//         <Box
//           sx={{
//             maxWidth: "1296px",
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             gap: "40px",
//             overflow: "hidden",
//           }}
//         >
//           <Box className="customize_title">
//             <Box className="customize_container_title">
//               <Typography>What can you customize?</Typography>
//             </Box>
//             <Box className="customize_container_subtitle">
//               <Typography>
//                 With our easy-to-use builder, you can replicate your ideal plan
//                 by selecting each page depending on your needs and goals
//               </Typography>
//             </Box>
//           </Box>
//           {isSlider ? (
//             <Box className="slider_card">
//               <Box
//                 sx={{
//                   display: "flex",
//                   transform: `translateX(${-currentIndex * 87}%)`,
//                   transition: "transform 0.5s ease-in-out",
//                   gap: "20px",
//                 }}
//               >
//                 <Box
//                   className="customize_div1"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon1.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>
//                         Vision, Goals, Habits and Ritual Trackers
//                       </Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img1.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//                 <Box
//                   className="customize_div2"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon6.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>
//                         Children and Family Resources and Tools
//                       </Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img6.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//                 <Box
//                   className="customize_div3"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon2.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>Health and Wellness Resources</Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img2.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//                 <Box
//                   className="customize_div4"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon3.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>Schedules and Calendars</Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img3.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//                 <Box
//                   className=" customize_div5"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon4.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>Productivity Tools</Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img4.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//                 <Box
//                   className="customize_div6"
//                   sx={{
//                     background: "#F3E8D0",
//                     padding: "40px 0 0 40px",
//                     maxWidth: "100%",
//                     borderRadius: "12px",
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     gap: "32px",
//                   }}
//                 >
//                   <Box sx={{ display: "flex", gap: "8px" }}>
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon5.svg"
//                       alt=""
//                     />
//                     <Box className="customize_card_title">
//                       <Typography>Add-ins: notes, lists or plans</Typography>
//                     </Box>
//                   </Box>
//                   <Box className="customize_img">
//                     <img
//                       src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img5.svg"
//                       alt=""
//                     />
//                   </Box>
//                 </Box>
//               </Box>
//             </Box>
//           ) : (
//             <Box className="customize_card">
//               <Box
//                 className="customize_div1"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon1.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>
//                       Vision, Goals, Habits and Ritual Trackers
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img1.svg"
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 className="customize_div2"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon6.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>
//                       Children and Family Resources and Tools
//                     </Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img6.svg"
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 className="customize_div3"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon2.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>Health and Wellness Resources</Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img2.svg"
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 className="customize_div4"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon3.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>Schedules and Calendars</Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     paddingTop: "10px",
//                   }}
//                 >
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img3.svg"
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 className=" customize_div5"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon4.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>Productivity Tools</Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={
//                       "https://byb-svg.s3.us-west-2.amazonaws.com/customize_img4.svg"
//                     }
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 className="customize_div6"
//                 sx={{
//                   background: "#F3E8D0",
//                   padding: "40px 0 0 40px",
//                   maxWidth: "100%",
//                   borderRadius: "12px",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "space-between",
//                   gap: "32px",
//                 }}
//               >
//                 <Box sx={{ display: "flex", gap: "8px" }}>
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon5.svg"
//                     alt=""
//                   />
//                   <Box className="customize_card_title">
//                     <Typography>Add-ins: notes, lists or plans</Typography>
//                   </Box>
//                 </Box>
//                 <Box
//                   sx={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img5.svg"
//                     alt=""
//                   />
//                 </Box>
//               </Box>
//             </Box>
//           )}

//           <Box className="customize_button">
//             <Box
//               sx={{
//                 display: "flex",
//                 gap: "8px",
//                 zIndex: "10",
//               }}
//             >
//               <IconButton
//                 onClick={handlePrev}
//                 sx={{
//                   color: "white",
//                   backgroundColor: "#03333314",
//                   "&:hover": { opacity: "70%" },
//                 }}
//               >
//                 <img
//                   src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-left.svg"
//                   alt=""
//                 />
//               </IconButton>
//               <IconButton
//                 onClick={handleNext}
//                 sx={{
//                   color: "white",
//                   backgroundColor: "#03333314",
//                   "&:hover": { opacity: "70%" },
//                 }}
//               >
//                 <img
//                   src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-right.svg"
//                   alt=""
//                 />
//               </IconButton>
//             </Box>
//             <button onClick={() => navigate("/cover")}>
//               Start Creating Yours!
//             </button>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// }

import React, { useEffect, useRef, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Customize() {
  const navigate = useNavigate();
  const [isSlider, setIsSlider] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  //   const swiper = useSwiper();

  const mySwiper1 = useRef();
  useEffect(() => {
    const handleResize = () => {
      setIsSlider(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    const next = mySwiper1.current.querySelector(".swiper-button-next");
    next.click();
  };

  const handlePrev = () => {
    const next = mySwiper1.current.querySelector(".swiper-button-prev");
    next.click();
  };

  return (
    <>
      <Box className="customize_container">
        <Box
          sx={{
            maxWidth: "1296px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "40px",
            overflow: "hidden",
          }}
        >
          <Box className="customize_title">
            <Box className="customize_container_title">
              <Typography>What can you customize?</Typography>
            </Box>
            <Box className="customize_container_subtitle">
              <Typography>
                With our easy-to-use builder, you can replicate your ideal plan
                by selecting each page depending on your needs and goals
              </Typography>
            </Box>
          </Box>
          {isSlider < 930 ? (
            <Box
              sx={{
                display: "block",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Swiper
                ref={mySwiper1}
                slidesPerView={
                  isSlider <= 930 && isSlider > 850
                    ? 2.4
                    : isSlider <= 850 && isSlider > 800
                    ? 2.2
                    : isSlider <= 800 && isSlider > 750
                    ? 2
                    : isSlider <= 750 && isSlider > 720
                    ? 1.9
                    : isSlider <= 720 && isSlider > 700
                    ? 1.8
                    : isSlider <= 700 && isSlider > 670
                    ? 1.7
                    : isSlider <= 670 && isSlider > 620
                    ? 2
                    : isSlider <= 620 && isSlider > 580
                    ? 1.85
                    : isSlider <= 580 && isSlider > 550
                    ? 1.75
                    : isSlider <= 550 && isSlider > 520
                    ? 1.65
                    : isSlider <= 520 && isSlider > 500
                    ? 1.56
                    : isSlider <= 500 && isSlider > 480
                    ? 1.5
                    : isSlider <= 480 && isSlider > 410
                    ? 1.4
                    : isSlider <= 410 && isSlider > 400
                    ? 1.23
                    : isSlider <= 400 && isSlider > 392
                    ? 1.2
                    : isSlider <= 392 && isSlider > 0
                    ? 1.16
                    : 0
                }
                centeredSlides={true}
                spaceBetween={10}
                grabCursor={true}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper1"
              >
                <SwiperSlide>
                  <Box
                    className="customize_div1"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon1.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>
                          Vision, Goals, Habits and Ritual Trackers
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img1.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className="customize_div2"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon6.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>
                          Children and Family Resources and Tools
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img6.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className="customize_div3"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon2.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>Health and Wellness Resources</Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img2.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className="customize_div4"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon3.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>Schedules and Calendars</Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img3.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className=" customize_div5"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon4.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>Productivity Tools</Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img4.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
                <SwiperSlide>
                  <Box
                    className="customize_div6"
                    sx={{
                      background: "#F3E8D0",
                      padding: "40px 0 0 40px",
                      maxWidth: "100%",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "32px",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon5.svg"
                        alt=""
                      />
                      <Box className="customize_card_title">
                        <Typography>Add-ins: notes, lists or plans</Typography>
                      </Box>
                    </Box>
                    <Box className="customize_img">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img5.svg"
                        alt=""
                      />
                    </Box>
                  </Box>
                </SwiperSlide>
              </Swiper>
            </Box>
          ) : (
            <Box className="customize_card">
              <Box
                className="customize_div1"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon1.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>
                      Vision, Goals, Habits and Ritual Trackers
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img1.svg"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                className="customize_div2"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon6.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>
                      Children and Family Resources and Tools
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img6.svg"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                className="customize_div3"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon2.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>Health and Wellness Resources</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img2.svg"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                className="customize_div4"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon3.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>Schedules and Calendars</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img3.svg"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                className=" customize_div5"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon4.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>Productivity Tools</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img4.svg"
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                className="customize_div6"
                sx={{
                  background: "#F3E8D0",
                  padding: "40px 0 0 40px",
                  maxWidth: "100%",
                  borderRadius: "12px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <Box sx={{ display: "flex", gap: "8px" }}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_icon5.svg"
                    alt=""
                  />
                  <Box className="customize_card_title">
                    <Typography>Add-ins: notes, lists or plans</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/customize_img5.svg"
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box className="customize_button">
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                zIndex: "10",
              }}
            >
              <IconButton
                onClick={() => handlePrev()}
                sx={{
                  color: "white",
                  backgroundColor: "#03333314",
                  "&:hover": { opacity: "70%" },
                }}
                className="customize-swiper-prev"
              >
                <img
                  src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-left.svg"
                  alt=""
                />
              </IconButton>
              <IconButton
                onClick={() => handleNext()}
                sx={{
                  color: "white",
                  backgroundColor: "#03333314",
                  "&:hover": { opacity: "70%" },
                }}
                className="customize-swiper-next"
              >
                <img
                  src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-right.svg"
                  alt=""
                />
              </IconButton>
            </Box>
            <button onClick={() => navigate("/cover")}>
              Start Creating Yours!
            </button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
