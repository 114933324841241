import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ButtonPrimary } from "../Buttons";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
  px: 7,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
};

const BasicModal = ({ open, onClose, updateUserStatrus }) => {
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        onClick: (e) => {
          e.stopPropagation();
        },
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <ButtonPrimary
          buttonText="CREATE ACCOUNT/LOGIN & CONTINUE"
          handelClick={(e) => {
            updateUserStatrus("AccountCheckout");
            navigate("/login?redirect=cart");
          }}
        />
        <Typography className="cartSubHeader2" sx={{ color: "#033333" }}>
          Or
        </Typography>
        <ButtonPrimary
          buttonText={`CONTINUE AS GUEST `}
          handelClick={(e) => {
            onClose();
            updateUserStatrus("Checkout");
          }}
        />
      </Box>
    </Modal>
  );
};

export default BasicModal;
