import React, { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import SwapHorizontalCircleOutlinedIcon from "@mui/icons-material/SwapHorizontalCircleOutlined";

import axios from "axios";
import config from "../../../config";

//component
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import MyAccountMenu from "../../../Components/MyAccountMenu";
import CoverBookImg from "../../../Assets/images/coverBook.png";

export default function MyAccount() {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate();

  const userSession = localStorage.getItem("User");
  const apiToken = localStorage.getItem("Token");
  const ApiHeaders = {
    Authorization: `Bearer ${apiToken}`,
    "Content-Type": "application/json", // Include this header if needed
  };

  const [activeTab, setActiveTab] = useState("MY-ACCOUNT");
  const [sideMenu, setSideMenu] = useState(false);
  const [userData, setUserData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [saveData, setSaveData] = useState([]);

  const [openOderPop, setOpenOderPop] = useState(false);
  const [orderCode, setOrderCode] = useState("");
  const [orderName, setOrderName] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [orderAmount, setOrderAmount] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderPaymentType, setOrderPaymentType] = useState("");
  const [orderPaymentDate, setOrderPaymentDate] = useState("");
  const [orderPaymentId, setOrderPaymentId] = useState("");
  const [orderPaymentStatus, setOrderPaymentStatus] = useState("");
  const [orderEmail, setOrderEmail] = useState("");
  const [orderPhone, setOrderPhone] = useState("");
  const [orderHouse, setOrderHouse] = useState("");
  const [orderStreet, setOrderStreet] = useState("");
  const [orderCity, setOrderCity] = useState("");
  const [orderState, setOrderState] = useState("");
  const [orderZipcode, setOrderZipcode] = useState("");
  const [orderCountry, setOrderCountry] = useState("");

  const options2 = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const handleClickOderDetail = (orderDetail) => {
    setOpenOderPop(true);
    setOrderCode(orderDetail.code);
    setOrderName(orderDetail.shipping_name);
    setOrderDate(
      new Intl.DateTimeFormat("en-GB", options2).format(
        new Date(orderDetail.created_at)
      )
    );
    // setOrderDate(orderDetail.created_at);
    setOrderAmount(orderDetail.total);
    setOrderStatus(orderDetail.status_title);
    setOrderPaymentType(orderDetail.payment_type);
    setOrderPaymentDate(
      new Intl.DateTimeFormat("en-GB", options2).format(
        new Date(orderDetail.payment_date)
      )
    );
    // setOrderPaymentDate(orderDetail.payment_date);
    setOrderPaymentId(orderDetail.payment_id);
    setOrderPaymentStatus(orderDetail.payment_status);
    setOrderEmail(orderDetail.shipping_email);
    setOrderPhone(orderDetail.shipping_phone);
    setOrderHouse(orderDetail.shipping_houseno);
    setOrderStreet(orderDetail.shipping_street);
    setOrderCity(orderDetail.shipping_city);
    setOrderState(orderDetail.shipping_state);
    setOrderZipcode(orderDetail.shipping_zipcode);
    setOrderCountry(orderDetail.shipping_country);
  };

  const handleCloseOderPop = () => {
    setOpenOderPop(false);
  };

  useEffect(() => {
    if (userSession !== undefined && userSession !== null) {
      axios
        .get(`${BACKEND_URL}/api/V1/myaccount/`, { headers: ApiHeaders })
        .then((response) => {
          const { User, Orders, SaveData } = response.data.result;
          setUserData(User);
          setOrderData(Orders);
          setSaveData(SaveData);
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      navigate("/");
    }
  }, []);

  const handleLoadDiary = (id) => {
    const saveDiaryItem = saveData.find((item) => item.id === id);
    const diaryData = JSON.parse(saveDiaryItem.diarydata);
    if (diaryData !== null) {
      localStorage.setItem("SaveDiaryId", id);

      // Parse each inner JSON string
      var Cover = diaryData.Cover;
      var FirstPage = diaryData.FirstPage;
      var Layout = diaryData.Layout;
      var Calendar = diaryData.Calendar;
      var Addins = diaryData.Addins;
      var Dates = diaryData.Dates;
      console.log("diaryData=>", diaryData);

      Cover != null && localStorage.setItem("Cover", Cover);
      FirstPage != null && localStorage.setItem("FirstPage", FirstPage);
      Layout != null && localStorage.setItem("Layout", Layout);
      Calendar != null && localStorage.setItem("Calendar", Calendar);
      Addins != null && localStorage.setItem("Addins", Addins);
      Dates != null && localStorage.setItem("Dates", Dates);
    }
  };

  return (
    <>
      <NavBar />
      <Box className="myAccountContainer">
        {/* Order Detail Popup */}
        <Dialog
          open={openOderPop}
          onClose={handleCloseOderPop}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="openTicketPopup"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box className="row2">
                <Box className="col-md-6">
                  <h5 className="popuptext">Order #190</h5>
                  <Box class="mb-3">
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Order Code</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderCode}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Order Date</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderDate}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Order Amount</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderAmount}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Payment Status</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderPaymentStatus}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Payment Type</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderPaymentType}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Payment Date</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderPaymentDate}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Payment Id</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderPaymentId}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Order Status</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderStatus}</Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="col-md-6">
                  <h5 className="popuptext">Shipping Detail</h5>
                  <Box class="mb-3">
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Name</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderName}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Email</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderEmail}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Phone</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderPhone}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">House</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderHouse}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Street</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderStreet}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">City</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderCity}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">State</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderState}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Zipcode</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderZipcode}</Box>
                    </Box>
                    <Box class="row">
                      <Box class="col-md-3 popuptext">
                        <label class="form-label">Country</label>
                      </Box>
                      <Box class="col-md-9 popuptext">{orderCountry}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Box sx={{ width: "100px", margin: "0px auto" }}>
              <Box
                sx={{ width: "100%" }}
                className="button"
                onClick={() => setOpenOderPop(false)}
              >
                <Typography sx={{ fontSize: "18px" }}>Close</Typography>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
        {/* Order Detail Popup */}
        <Box className="myAccountHeader">
          <Typography className="myAccountHeaderText">
            Hello, {userData.first_name}{" "}
            {userData.last_name != null ? userData.last_name : ""}
          </Typography>
          <Box className="myAccountHeaderNavigation">
            <Typography mr={1}>HOME / </Typography>
            <Typography mr={1}>ACCOUNT / </Typography>
            <Typography mr={1}>MY ACCOUNT </Typography>
          </Box>
        </Box>

        <Box className="myAccountInfoBox">
          <MyAccountMenu />

          <Box className="myAccountDetailsBox">
            {/* my account info  */}
            <Box className="myAccountTab">
              <SwapHorizontalCircleOutlinedIcon
                onClick={() => setSideMenu(!sideMenu)}
                className="sideMenuIcon"
              />
              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">
                  Recent Projects
                </Typography>
              </Box>

              {/* Project Table */}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow align="left">
                      <TableCell>PROJECT</TableCell>
                      <TableCell>PROJECT NAME</TableCell>
                      <TableCell>ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saveData.length > 0 &&
                      saveData.map((item) => {
                        const diaryData = JSON.parse(item.diarydata);
                        const coverData = diaryData.Cover
                          ? JSON.parse(diaryData.Cover)
                          : "";
                        const firstPageData = diaryData.FirstPage
                          ? JSON.parse(diaryData.FirstPage)
                          : "";
                        return (
                          <TableRow
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            align="left"
                          >
                            <TableCell component="th" scope="row">
                              {coverData?.CoverType === "predesign" && (
                                <img
                                  src={coverData?.FrontImage || CoverBookImg}
                                  alt="Front Cover"
                                  width="50"
                                />
                              )}
                            </TableCell>

                            <TableCell>
                              {firstPageData?.Name}
                              <Typography
                                className="load-design pointer hideDesktop"
                                onClick={() => handleLoadDiary(item.id)}
                              >
                                Load design
                              </Typography>
                            </TableCell>
                            <TableCell className="loadcell">
                              <Typography
                                className="load-design pointer"
                                onClick={() => handleLoadDiary(item.id)}
                              >
                                Load design
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Project Table */}
              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">
                  Recent Orders
                </Typography>
                <Typography
                  className="myAccountTabLinkText"
                  onClick={() => {
                    navigate("/myorders");
                  }}
                >
                  VIEW ALL
                </Typography>
                {/*<Typography className="myAccountTabLinkText">OPEN TICKET</Typography>*/}
              </Box>

              {/* Order Table */}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow align="left">
                      <TableCell>ORDER NUMBER</TableCell>
                      <TableCell>DATE</TableCell>
                      <TableCell>SHIP TO</TableCell>
                      <TableCell>ORDER TOTAl</TableCell>
                      <TableCell>STATUS</TableCell>
                      <TableCell>ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData.map((order) => (
                      <TableRow
                        key={order.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        align="left"
                      >
                        <TableCell component="th" scope="row">
                          {order.code}
                        </TableCell>
                        <TableCell>
                          {new Intl.DateTimeFormat("en-GB", options2).format(
                            new Date(order.created_at)
                          )}
                        </TableCell>
                        <TableCell>{order.shipping_name}</TableCell>
                        <TableCell>{order.total}</TableCell>
                        <TableCell>{order.status_title}</TableCell>
                        <TableCell onClick={() => handleClickOderDetail(order)}>
                          <Typography className="pointer">details</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Order Table */}
              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">
                  Account Information
                </Typography>
              </Box>

              <Box className="myAccountTabItem2">
                <Typography className="myAccountTabSubHeaderEmail">
                  CONTACT INFORMATION
                </Typography>
                <Typography className="myAccountTabSubHeaderEmail">
                  {userData.email}
                </Typography>
                <Typography className="myAccountTabSubHeaderEmail">
                  {userData.phone}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    mr={11}
                    className="myAccountTabLinkText"
                    onClick={() => {
                      navigate("/edit-profile");
                    }}
                  >
                    EDIT
                  </Typography>
                  <Typography
                    className="myAccountTabLinkText"
                    onClick={() => {
                      navigate("/change-password");
                    }}
                  >
                    CHANGE PASSWORD
                  </Typography>
                </Box>
              </Box>

              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">
                  Address Book
                </Typography>
                <Typography
                  className="myAccountTabLinkText"
                  onClick={() => {
                    navigate("/addressbook");
                  }}
                >
                  MANAGE ADDRESS
                </Typography>
              </Box>

              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">0</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
