import React from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { ButtonPrimary } from "../../../Components/Buttons";
import { useNavigate } from "react-router-dom";

export default function Hero() {
  const navigate = useNavigate();

  return (
    <Box className="hero">
      <Box>
        <Box className="heroMain">
          <Box className="mainH" sx={{ marginTop: "15px" }}>
            <Box className="mainTitle">
              <Typography>
                A planner built by <br /> you, for you
              </Typography>
            </Box>
            <Box className="mainSubTitle">
              <Typography>
                Design your future, one perfectly - planned page at a time
              </Typography>
            </Box>
            <Box className="hero_button">
              <ButtonPrimary
                buttonText="Learn More"
                width="162px"
                textSize="14px"
                margin="0"
                handelClick={() => navigate("/cover")}
              />
              <Box className="hero_video pointer">
                <img
                  src="https://byb-svg.s3.us-west-2.amazonaws.com/play.svg"
                  alt=""
                />
                <Typography>Take a Tour of the Planner</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="heroImg" sx={{ marginRight: "50px" }}>
            <img
              src="https://byb-svg.s3.us-west-2.amazonaws.com/Danica-Corin-Cover.svg"
              alt=""
            />
          </Box>
        </Box>
        <Box className="heroBottom">
          <Box className="imgBox">
            <Box className="heroBottom_title">
              <Typography>Join clients who trust us</Typography>
            </Box>
            <Box className="imgLine">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/1.svg"
                alt=""
              />
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/2.svg"
                alt=""
              />
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/3.svg"
                alt=""
              />
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/4.svg"
                alt=""
              />
              <Box className="leftBox"></Box>
              <Box className="rightBox"></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
