import React, { useRef, useState } from "react";
import "./style.css";
import { Box } from "@mui/material";

export default function VideoItems({ videoUrl }) {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    const video = videoRef.current;
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };
  return (
    <Box sx={{ position: "relative" }}>
      <video ref={videoRef} className="video" autoPlay muted loop playsInline>
        <source src={videoUrl} type="video/mp4" />
      </video>
      <Box
        onClick={toggleMute}
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          color: "white",
          border: "none",
          cursor: "pointer",
          background: "#033333",
          padding: "8px",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isMuted ? (
          <img
            width={14}
            height={14}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/mute.svg"
            alt=""
          />
        ) : (
          <img
            width={14}
            height={14}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/unmute.svg"
            alt=""
          />
        )}
      </Box>
    </Box>
  );
}
