import React, { useState, useEffect, useRef } from "react";
import { Typography, Box } from "@mui/material";
//import { FileDrop } from 'react-file-drop'
import config from "../../config";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../layout.css";
import "./style.css";

import coverPhoto from "../../Assets/images/CoverPhoto.png";
import coverBackPhoto from "../../Assets/images/BackCoverPhoto.png";

//Component
import NavBar from "../NavBar";
import Footer from "../Footer";
import UploadPopup from "../../Components/UploadPopup";
import { useLocation } from "react-router-dom";

export default function Cover() {
  const location = useLocation();
  const hashValue = location.hash.replace("#", "");

  const [tab, setTab] = useState(hashValue == "FirstPage" ? false : true);
  const fileFrontInputRef = useRef(null);
  const fileBackInputRef = useRef(null);
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const nextYear = currentYear + 1;

  const [checkboxFront, setCheckboxFront] = useState(true);
  const [checkboxBack, setCheckboxBack] = useState(false);

  const [diaryYear, setDiaryYear] = useState(currentYear + "-" + nextYear);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameLoad, setNameLoad] = useState(false);
  const [emailLoad, setEmailLoad] = useState(false);
  const [phoneLoad, setPhoneLoad] = useState(false);
  const [messageLoad, setMessageLoad] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [coverOption, setCoverOption] = useState(3);
  const [coverCategoryData, setCoverCategoryData] = useState([]);
  const [coversData, setCoversData] = useState([]);

  const [coverImage, setCoverImage] = useState(coverPhoto);
  const [selectedCoverId, setSelectedCoverId] = useState("");
  const [coverThumbnail, setCoverThumbnail] = useState("");

  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [showCustomCover, setShowCustomCover] = useState(false);
  const [customFrontImage, setCustomFrontImage] = useState("");
  const [customBackImage, setCustomBackImage] = useState("");
  const [customCoverPreview, setCustomCoverPreview] = useState("");
  const [customPreviewType, setCustomPreviewType] = useState("Front");

  const [imagePopup, setImagePopup] = useState(false);
  const [imageUploadType, setImageUploadType] = useState(false);
  const [priceBox, setPriceBox] = useState(1);
  const [menuPopInfo, setMenuPopInfo] = useState(false);

  useEffect(() => {
    console.log("aaaaaaaaaaaa");
    const BACKEND_URL = config.BACKEND_URL;

    if (!localStorage.getItem("coverpopup")) {
      setMenuPopInfo(true);
    }

    const fetchCoverCategories = async () => {
      await axios
        .get(`${BACKEND_URL}/api/V1/covers/`)
        .then((response) => {
          setCoverCategoryData(response.data.result.categories);
          setCoversData(response.data.result.covers);
        })
        .catch((error) => {
          console.log("Error fetching cover Categories data:", error);
        });
    };
    fetchCoverCategories();

    //load cover data from session on pageload
    let coverData = localStorage.getItem("Cover");
    console.log("coversData", coversData);
    if (coverData !== null) {
      coverData = JSON.parse(coverData);
      if (coverData.CoverType === "predesign") {
        console.log("111111");
        setSelectedCoverId(coverData.CoverId);
        setCoverImage(coverData.FrontImage);
        setFrontImage("");
        setBackImage("");
        setShowCustomCover(false);
        setPriceBox(priceBox + 1);
      }
      if (coverData.CoverType === "custom") {
        console.log("222222");

        setSelectedCoverId(0);
        setCoverImage("");
        setCoverOption("Custom");
        setShowCustomCover(true);
        setCustomPreviewType("Front");
        setCustomFrontImage(coverData.FrontImage);
        setCustomBackImage(coverData.BackImage);
        coverData.FrontImage && setCheckboxFront(true);
        coverData.BackImage && setCheckboxBack(true);
        setPriceBox(priceBox + 1);
      }
    }

    // Pinterest tracking code
    PinterestTrackCode();
  }, []);

  useEffect(() => {
    var coverData = localStorage.getItem("Cover");
    if (coverData === null && coversData.length > 0) {
      handleSelectedCover(coversData[2]?.coverList[0]);
    }
  }, [coversData]);

  const PinterestTrackCode = () => {
    // Pinterest tracking code
    if (typeof pintrk === "function") {
      window.pintrk("track", "pagevisit", {
        event_id: "eventId0001",
      });
    }
  };

  const handleCoverOption = (id) => {
    setCoverOption(id);
    if (id == "Custom") {
      setShowCustomCover(true);
      setCustomPreviewType("Front");
    }
  };

  const handleCheckboxFrontChange = () => {
    setCheckboxFront(!checkboxFront);
  };

  const handleCheckboxBackChange = () => {
    setCheckboxBack(!checkboxBack);
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onFrontFileInput = async (event) => {
    const files = event.target.files;
    if (checkboxFront && files.length === 1) {
      const dataURL1 = await readFileAsDataURL(files[0]);
      setCustomFrontImage(dataURL1);
      setImageUploadType("Front");
      setImagePopup(true);
      console.log("onFrontFileInput", imageUploadType);
    }
  };

  const onBackFileInput = async (event) => {
    const files = event.target.files;
    if (checkboxBack && files.length === 1) {
      const dataURL1 = await readFileAsDataURL(files[0]);
      setCustomBackImage(dataURL1);
      setImageUploadType("Back");
      setImagePopup(true);
    }
  };

  /*const handleDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;

    if((checkboxFront || checkboxBack) && files.length===1 ){
      const dataURL1 = await readFileAsDataURL(files[0]);
      if(!checkboxFront  && checkboxBack)
        setBackImage(dataURL1);
      else
        setFrontImage(dataURL1);

      setImagePopup(true);
      
    }else{

      try {
        const dataURL1 = await readFileAsDataURL(files[0]);
        const dataURL2 = await readFileAsDataURL(files[1]);
        
        setFrontImage(dataURL1);
        setBackImage(dataURL2);
        setImagePopup(true);
      } catch (error) {
        console.log('Error reading files:', error);
      }
    } 
   
    
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };*/

  const handleSelectedCover = (cover) => {
    console.log("101010", cover);

    setSelectedCoverId(cover?.id);
    setCoverImage(cover?.front_image);
    setCoverThumbnail(cover?.images[0].image);
    setShowCustomCover(false);
    setFrontImage("");
    setPriceBox(priceBox + 1);

    localStorage.setItem(
      "Cover",
      JSON.stringify({
        CoverType: "predesign",
        CoverId: cover?.id,
        FrontImage: cover?.front_image,
        BackImage: cover?.back_image,
        price: cover?.price,
      })
    );
  };

  const handleCustomCover = (type, img) => {
    console.log("2020202");

    console.log("Custom Front Image=>", type, img, frontImage, backImage);

    setCustomCoverPreview(img);
    setCoverThumbnail(img);
    setShowCustomCover(true);
    setImagePopup(false);
    setPriceBox(priceBox + 1);

    if (type == "Back") {
      setCustomBackImage(img);
      localStorage.setItem(
        "Cover",
        JSON.stringify({
          CoverType: "custom",
          FrontImage: customFrontImage,
          BackImage: img,
          price: 70,
        })
      );
      localStorage.setItem(
        "FirstPage",
        JSON.stringify({
          Year: diaryYear,
          Name: name || "",
          Email: email || "",
          Phone: phone || "",
          Message: message || "",
        })
      );
    } else {
      setCustomFrontImage(img);
      localStorage.setItem(
        "Cover",
        JSON.stringify({
          CoverType: "custom",
          FrontImage: img,
          BackImage: customBackImage,
          price: 70,
        })
      );
      localStorage.setItem(
        "FirstPage",
        JSON.stringify({
          Year: diaryYear,
          Name: name || "",
          Email: email || "",
          Phone: phone || "",
          Message: message || "",
        })
      );
    }
  };

  const handleCustomCancel = (type) => {
    if (type == "Back") {
      setCustomBackImage("");
    } else {
      setCustomFrontImage("");
    }
    setCustomCoverPreview("");
    setCustomPreviewType("Front");
    setCoverThumbnail("");
    setPriceBox(priceBox + 1);
    localStorage.removeItem("Cover");
  };

  const selectCoverThumbnail = (imgObj) => {
    console.log(imgObj);
    setCoverImage(imgObj.image);
    setCoverThumbnail(imgObj.image);
  };
  const selectCustomThumbnail = (img, type) => {
    console.log(img);
    setCustomPreviewType(type);
    setCustomCoverPreview(img);
    setCoverThumbnail(img);
  };

  const downloadImage = (type) => {
    // Convert dataURL to Blob
    // Create anchor element

    var blobURL = "";
    if (type == "Back") {
      blobURL = customBackImage;
    } else {
      blobURL = customFrontImage;
    }
    const a = document.createElement("a");
    a.href = blobURL;
    a.download = type == "Back" ? "cropped_back.jpg" : "cropped_front.jpg"; // Set the filename here
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the DOM
    document.body.removeChild(a);
  };

  const closePopup = () => {
    localStorage.setItem("coverpopup", "true");
    setMenuPopInfo(false);
  };
  console.log("coversData", coversData);

  return (
    <>
      <NavBar updatePriceBox={priceBox} />
      <Box className="PageContainer">
        <Box className="PageBox">
          {/* CoverBox1 */}
          <Box className="CoverBox1 PageInnerBox ">
            <Box className="LeftPanelBox noscroll">
              <Box className="LeftHeader">
                <Typography className="LeftTitle">Cover Options</Typography>
                <Typography className="LeftSubText">
                  To pick your pattern, select a category and explore....
                </Typography>
              </Box>

              <Box className="coverOptionItems">
                {coverCategoryData.map((cat, index) => (
                  <Box
                    key={`covercat${index}`}
                    onClick={() => handleCoverOption(cat?.id)}
                    sx={{
                      background: coverOption === cat?.id ? "#2E513E" : "#fff",
                    }}
                    className="coverOptionItem"
                  >
                    <p
                      style={{
                        color: coverOption === cat?.id ? "#fff" : "#355C5C",
                      }}
                    >
                      {cat.title}{" "}
                    </p>
                  </Box>
                ))}

                {/*<Box key={`covercat-custom`} onClick={() => handleCoverOption("Custom")} sx={{ background: coverOption === "Custom" ? "#e9e9e9" : "#fff" }} className="coverOptionItem">
                    <Typography>Custom</Typography>
                  </Box>*/}
              </Box>

              <Box className="LeftPanelInner">
                <Box className="ChooseFileBox">
                  {coversData.map((cat, index) => (
                    <Box
                      sx={{
                        display: coverOption === cat.cat_id ? "flex" : "none",
                      }}
                      className={`Box${cat.cat_id} PreCoverBox`}
                      key={`cat${index}`} // Use a unique key based on index or cat.cat_id
                    >
                      {cat?.coverList?.map((cover, coverIndex) => (
                        <Box className="CoverItem" key={`cover-${cover?.id}`}>
                          {" "}
                          {/* Add a unique key */}
                          <img
                            style={{
                              display:
                                selectedCoverId === cover?.id
                                  ? "block"
                                  : "none",
                            }}
                            className="GTic"
                            src="https://byb-svg.s3.us-west-2.amazonaws.com/GTic.png+(1).svg"
                            alt="close"
                          />
                          <img
                            src={cover?.front_image}
                            alt={cover.title}
                            onClick={() => handleSelectedCover(cover)}
                          />
                        </Box>
                      ))}
                    </Box>
                  ))}

                  <Box
                    sx={{
                      display: coverOption === "Custom" ? "block" : "none",
                    }}
                    className="customBox"
                  >
                    <Box className="ChFHeaderBox">
                      <Typography className="chFHeaderText">
                        Custom Upload
                      </Typography>
                      {/*<Typography className='chFSubText'>Want to upload a corporate or group cover? Please email <span>support@becomingyourbest.com</span> to coordinate print dimensions and proofs.</Typography>*/}
                    </Box>
                    {/*<Box className="chooseCoverRadioBox">
                        <Box className="FontCoverBox chooseCoverCheckBox">
                          <input id="myCheckbox" type="checkBox"
                            checked={checkboxFront}
                            onChange={handleCheckboxFrontChange}
                          />
                          <Typography ml={2}>upload a front cover</Typography>
                        </Box>
                        <Box className="BackCoverBox chooseCoverCheckBox">
                          <input id="myCheckbox" type="checkBox"
                            checked={checkboxBack}
                            onChange={handleCheckboxBackChange}
                          />
                          <Typography ml={2}>upload a BACK cover</Typography>
                        </Box>
                      </Box>
                      <Box className="frontupload" >
                      {checkboxFront && customFrontImage=="" && <>
                            <input
                              onChange={onFrontFileInput}
                              ref={fileFrontInputRef}
                              type="file"
                              className="hidden"
                              multiple
                            />
                            <Box onClick={() =>{ setCustomPreviewType('Front');fileFrontInputRef.current.click();}} className="chooseFileBtn">
                              <Typography>Choose a Front Cover</Typography>
                            </Box>
                          </>}
                        
                        {checkboxFront && customFrontImage!=="" && <Box onClick={() => handleCustomCancel("Front")} className="chooseFileBtn">
                              <Typography>Cancel Front Cover</Typography>
                            </Box>}
                        
                          {checkboxBack && customBackImage=="" && <>
                              <input
                                onChange={onBackFileInput}
                                ref={fileBackInputRef}
                                type="file"
                                className="hidden"
                                
                              />
                              <Box onClick={() =>{setCustomPreviewType('Back'); fileBackInputRef.current.click();}} className="chooseFileBtn">
                                <Typography>Choose a Back Cover</Typography>
                              </Box>
                              </>
                            }

                            {checkboxBack && customBackImage!=="" && <Box onClick={() => handleCustomCancel("Back")} className="chooseFileBtn">
                              <Typography>Cancel Back Cover</Typography>
                            </Box>}
                          
                        
                      </Box>*/}
                    <Box
                      className="chooseCoverRadioBox"
                      sx={{ height: "200px", justifyContent: "center" }}
                    >
                      Coming Fall 2024
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display:
                    localStorage.getItem("Cover") != null ? "block" : "none",
                }}
                onClick={() => (window.location.href = "/firstpage")}
                className="chooseFileBtn deskbtn"
              >
                <Typography>Next</Typography>
              </Box>
            </Box>
            <Box className="RightPanelBox">
              <Box className="PreviewContainer">
                {!showCustomCover && (
                  <>
                    <Box className="viewCoverBox">
                      {coverImage && <img src={coverImage} alt="CoverPhoto" />}
                    </Box>
                    {/* <Box className="cover_thumbnails">
                      {coversData.map((cat, index) =>
                        cat?.coverList?.map(
                          (cover, coverIndex) =>
                            selectedCoverId === cover?.id && (
                              <Box
                                className="CoverItem"
                                key={`cover-${cover?.id}`}
                              >
                                {cover?.images?.map((imgObj, imgIndex) => (
                                  <img
                                    src={imgObj.image}
                                    alt=""
                                    className={`thumbnail ${
                                      coverThumbnail == imgObj.image
                                        ? "active"
                                        : ""
                                    }`}
                                    key={`img-${imgIndex}`}
                                    onClick={() => {
                                      selectCoverThumbnail(imgObj);
                                    }}
                                  />
                                ))}
                              </Box>
                            )
                        )
                      )}
                    </Box> */}
                  </>
                )}

                {showCustomCover && (
                  <>
                    <Box className="viewCoverBox">
                      {customPreviewType == "Front" && (
                        <img src={coverPhoto} alt="CoverPhoto" />
                      )}
                      {customPreviewType == "Back" && (
                        <img src={coverBackPhoto} alt="CoverPhoto" />
                      )}
                      <Box
                        className={`CustomCoverImg ${
                          customPreviewType == "Back" ? "Back" : "Front"
                        }`}
                      >
                        {customPreviewType == "Front" && customFrontImage && (
                          <img src={customFrontImage} alt="" />
                        )}
                        {customPreviewType == "Back" && customBackImage && (
                          <img src={customBackImage} alt="" />
                        )}
                      </Box>
                    </Box>
                    <Box className="cover_thumbnails">
                      <Box className="CoverItem">
                        {customFrontImage && (
                          <div>
                            <img
                              src={customFrontImage}
                              alt=""
                              className={`thumbnail ${
                                coverThumbnail == customFrontImage
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                selectCustomThumbnail(
                                  customFrontImage,
                                  "Front"
                                );
                              }}
                            />
                            <br />
                            <Typography>Front Cover</Typography>
                          </div>
                        )}
                        {customBackImage && (
                          <div>
                            <img
                              src={customBackImage}
                              alt=""
                              className={`thumbnail ${
                                coverThumbnail == customBackImage
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                selectCustomThumbnail(customBackImage, "Back");
                              }}
                            />
                            <br />
                            <Typography>Back Cover</Typography>
                          </div>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          className={
            menuPopInfo ? "PopInfo cover arrow-top open" : "PopInfo cover"
          }
        >
          <Box className="PopInfo-wrapper">
            <Typography className="PopInfoClose" onClick={closePopup}>
              X
            </Typography>
            <Typography className="PopInfoText">
              <span>What's Here</span>
              You'll see two menu options in the submenu above: “Cover” and
              “First Page.” These two pages are for selecting your cover and
              creating your own personalized first page.
            </Typography>
            <Typography className="PopInfoText">
              <span>The Design Bar</span>
              Use the design bar to the left to find and select your cover. Once
              selected, push “next” and you can then create your personalized
              front page with your name, contact info, and a personal message.
            </Typography>
            <Typography className="PopInfoText">
              Let the creativity and fun begin! 😃
            </Typography>

            <Box className="PopInfoBtnClose button" onClick={closePopup}>
              <p>Close</p>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* upload popup*/}
      <UploadPopup
        showPopup={imagePopup}
        setShowPopup={setImagePopup}
        uploadType={imageUploadType}
        getFrontImage={customFrontImage}
        getBackImage={customBackImage}
        setCustomCover={handleCustomCover}
      />

      <ToastContainer autoClose={false} draggable={false} />

      <Footer />
    </>
  );
}
