import React from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Design() {
  const navigate = useNavigate();

  return (
    <Box className="design_container">
      <Box
        sx={{
          width: "1296px",
          padding: "265px 363px 271px 357px",
          position: "relative",
        }}
        className="design_container_main"
      >
        <Box
          sx={{
            maxWidth: "720px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box className="design_container_title">
            <Typography>Empowering You to Become Your Best</Typography>
          </Box>
          <Box className="design_container_subtitle">
            <Typography>
              At Becoming Your Best, we are dedicated to providing the world’s
              best time-management and well-being tools. Our mission is to help
              you focus on what matters most, prioritize health and
              relationships, and achieve more with less stress. We're passionate
              about empowering you to lead a fulfilling, balanced life.
            </Typography>
          </Box>
          <Box className="design_container_button">
            <button>Learn more about planning </button>
          </Box>
        </Box>
        <Box className="img1">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design1.svg"
            alt=""
          />
        </Box>
        <Box className="img2">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design2.svg"
            alt=""
          />
        </Box>
        <Box className="img3">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design3.svg"
            alt=""
          />
        </Box>
        <Box className="img4">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design4.svg"
            alt=""
          />
        </Box>
        <Box className="icon1">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design_icon1.svg"
            alt=""
          />
        </Box>
        <Box className="icon2">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design_icon2.svg"
            alt=""
          />
        </Box>
        <Box className="icon3">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design_icon3.svg"
            alt=""
          />
        </Box>
        <Box className="icon4">
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/design_icon4.svg"
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
}
