import { Box, Typography } from "@mui/material";
import React from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../../../Components/Buttons";
export default function Card() {
  const navigate = useNavigate();

  return (
    <Box className="card">
      <Box className="div1">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <img
            width={32}
            height={32}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/one.svg"
            alt=""
          />
          <Box className="cardTitle">
            <Typography>Create your cover and first page</Typography>
          </Box>
          <Box className="cardSubTitle">
            <Typography>
              Design and customize the cover and first page of your planner
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/COVER.svg"
            alt=""
          />
        </Box>
      </Box>
      <Box className="div2">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <img
            width={32}
            height={32}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/two.svg"
            alt=""
          />
          <Box className="cardTitle">
            <Typography>Customize your weekly or daily schedule</Typography>
          </Box>
          <Box className="cardSubTitle">
            <Typography>
              Tailor your weekly or daily schedule to fit your unique routine{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          className="div2_img"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/planner.svg"
            alt=""
          />
        </Box>
      </Box>
      <Box className="div3">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <img
            width={32}
            height={32}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/three.svg"
            alt=""
          />
          <Box className="cardTitle">
            <Typography>Include add-ins specific to your life</Typography>
          </Box>
          <Box className="cardSubTitle">
            <Typography>
              Choose a category, and then select add-in template (s).{" "}
            </Typography>
          </Box>
        </Box>
        <Box
          className="div3_img"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/planner2.svg"
            alt=""
          />
        </Box>
      </Box>
      <Box className="div4">
        <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <img
            width={32}
            height={32}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/four.svg"
            alt=""
          />
          <Box className="cardTitle">
            <Typography>Create your cover and first page</Typography>
          </Box>
          <Box className="cardSubTitle">
            <Typography>
              Design and customize the cover and first page of your planner
            </Typography>
          </Box>
        </Box>
        <Box
          className="div4_img"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="https://byb-svg.s3.us-west-2.amazonaws.com/planner3.svg"
            alt=""
          />
        </Box>
      </Box>
      <Box className="div5">
        <Box className="div5_img" sx={{ display: "flex" }}>
          <img
            width={400}
            height={436}
            src="https://byb-svg.s3.us-west-2.amazonaws.com/Customization.svg"
            alt=""
          />
        </Box>
        <Box className="div5_box">
          <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <img
                width={32}
                height={32}
                src="https://byb-svg.s3.us-west-2.amazonaws.com/done.svg"
                alt=""
              />
              <Box className="getStartTitle">
                <Typography>
                  {" "}
                  Then review it to make sure it looks right!
                </Typography>
              </Box>
              <Box className="getStartSubTitle">
                <Typography>
                  {" "}
                  Here is what you've added to your planner Everything look
                  okay? Let's wrap this up! :)
                </Typography>
              </Box>
            </Box>

            <ButtonPrimary
              buttonText="Get Started"
              width="164px"
              textSize="14px"
              margin="0"
              handelClick={() => navigate("/cover")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
