import React, { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import "./style.css";

function StoriesSlider() {
  const [maxHeight, setMaxHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  useEffect(() => {
    const items = Array.from(document.querySelectorAll(".storiesCard"));
    let max = 0;
    items.forEach((item) => {
      const slideHeight = item.clientHeight;
      max = Math.max(max, slideHeight);
    });
    setMaxHeight(max);
    const interval = setInterval(() => {
      handleNext();
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  const handleNext = () => {
    if (isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
      setIsSliding(false);
    }, 300);
  };

  const handleSlideChange = (index) => {
    console.log("index", index);
    if (index === currentIndex || isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      setCurrentIndex(index - 1);
      setIsSliding(false);
    }, 300);
  };

  return (
    <Box sx={{ position: "relative", marginBottom: "80px" }}>
      <Box
        sx={{
          position: "relative",
          width: "1296px",
          margin: "0 auto",
          overflow: "hidden",
          borderRadius: "12px",
        }}
        className="rewiewBox"
      >
        <Box
          sx={{
            display: "flex",
            transform: `translateX(${-currentIndex * 100}%)`,
            transition: "transform 0.5s ease-in-out",
          }}
        >
          <Card className="sliderBox">
            <Box
              sx={{
                background: "#F8F4EB",
                padding: "64px 80px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                borderRadius: "16px",
              }}
              className="sliderBox_main"
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Box className="slider_title">
                  <Typography>
                    It’s Not <span>Just</span> a Planner!
                  </Typography>
                </Box>
                <Box className="slider_subtitle">
                  <Typography>What are the key features?</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box
                  sx={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box className="card_title">
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/customizable.svg"
                        alt=""
                      />
                    </Box>
                    <Typography>Customizable Layouts</Typography>
                  </Box>
                  <Box className="card_subtitle">
                    <Typography>
                      Create a planner that fits your life with options like
                      daily or weekly pages, habit tracking, and mental
                      planning.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="stylish_title pointer"
                  onClick={() => handleSlideChange(2)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/durable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Durable, Stylish Covers</Typography>
                </Box>
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(3)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/high.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>High-Quality Writing Experience</Typography>
                </Box>
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(4)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/time.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Time-Management Guru</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="slider_img">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/slider0.svg"
                alt=""
              />
            </Box>
          </Card>
          <Card className="sliderBox">
            <Box
              sx={{
                background: "#F8F4EB",
                padding: "64px 80px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                borderRadius: "16px",
              }}
              className="sliderBox_main"
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Box className="slider_title">
                  <Typography>
                    It’s Not <span>Just</span> a Planner!
                  </Typography>
                </Box>
                <Box className="slider_subtitle">
                  <Typography>What are the key features?</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box
                  className="stylish_title pointer"
                  onClick={() => handleSlideChange(1)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/customizable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography> Customizable Layouts</Typography>
                </Box>
                <Box
                  sx={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box className="card_title">
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/durable.svg"
                        alt=""
                      />
                    </Box>
                    <Typography>Durable, Stylish Covers</Typography>
                  </Box>
                  <Box className="card_subtitle">
                    <Typography>
                      Select from a range of durable and beautiful covers with
                      designer and custom options.
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(3)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/high.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>High-Quality Writing Experience</Typography>
                </Box>
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(4)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/time.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Time-Management Guru</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="slider_img">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/slider1.svg"
                alt=""
              />
            </Box>
          </Card>
          <Card className="sliderBox">
            <Box
              sx={{
                background: "#F8F4EB",
                padding: "64px 80px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                borderRadius: "16px",
              }}
              className="sliderBox_main"
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Box className="slider_title">
                  <Typography>
                    It’s Not <span>Just</span> a Planner!
                  </Typography>
                </Box>
                <Box className="slider_subtitle">
                  <Typography>What are the key features?</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(1)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/customizable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Customizable Layouts</Typography>
                </Box>
                <Box
                  className="stylish_title pointer"
                  onClick={() => handleSlideChange(2)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/durable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Durable, Stylish Covers</Typography>
                </Box>

                <Box
                  sx={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box className="card_title">
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/high.svg"
                        alt=""
                      />
                    </Box>
                    <Typography>High-Quality Writing Experience</Typography>
                  </Box>
                  <Box className="card_subtitle">
                    <Typography>
                      Write on premium thick artisan paper designed for
                      durability and a smooth writing experience.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(4)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/time.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Time-Management Guru</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="slider_img">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/slider2.svg"
                alt=""
              />
            </Box>
          </Card>
          <Card className="sliderBox">
            <Box
              sx={{
                background: "#F8F4EB",
                padding: "64px 80px",
                display: "flex",
                flexDirection: "column",
                gap: "40px",
                borderRadius: "16px",
              }}
              className="sliderBox_main"
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <Box className="slider_title">
                  <Typography>
                    It’s Not <span>Just</span> a Planner!
                  </Typography>
                </Box>
                <Box className="slider_subtitle">
                  <Typography>What are the key features?</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(1)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/customizable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Customizable Layouts</Typography>
                </Box>
                <Box
                  className="stylish_title pointer"
                  onClick={() => handleSlideChange(2)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/durable.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>Durable, Stylish Covers</Typography>
                </Box>

                <Box
                  className="high_title pointer"
                  onClick={() => handleSlideChange(3)}
                >
                  <Box
                    sx={{
                      width: "28px",
                      height: "28px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      src="https://byb-svg.s3.us-west-2.amazonaws.com/high.svg"
                      alt=""
                    />
                  </Box>
                  <Typography>High-Quality Writing Experience</Typography>
                </Box>
                <Box
                  sx={{
                    padding: "20px",
                    background: "white",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Box className="card_title">
                    <Box
                      sx={{
                        width: "28px",
                        height: "28px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/time.svg"
                        alt=""
                      />
                    </Box>
                    <Typography>Time-Management Guru</Typography>
                  </Box>
                  <Box className="card_subtitle">
                    <Typography>
                      Access and learn skills from time-management experts!
                      Achieve 30-50% more with less stress, better health, and
                      better relationships.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="slider_img">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/slider3.svg"
                alt=""
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

export default StoriesSlider;
