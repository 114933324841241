import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import "./style.css";

export default function GiftTabs({ selectedTab, updateTab }) {
  const [tab, setTab] = useState("GiftCard");

  useEffect(() => {
    setTab(selectedTab);
  }, []);

  const handleTab = (tabname) => {
    setTab(tabname);
    updateTab(tabname);
  };

  return (
    <>
      <Box className="pagetab-list twobtn">
        <Box
          className={tab === "GiftCard" ? "pagetab active" : "pagetab"}
          onClick={() => handleTab("GiftCard")}
        >
          <Typography>Gift Card</Typography>
        </Box>
        <Box
          className={tab === "AccessoryKit" ? "pagetab active" : "pagetab"}
          onClick={() => handleTab("AccessoryKit")}
        >
          <Typography>Accessory Kit</Typography>
        </Box>
      </Box>
    </>
  );
}
