import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css";
import Card from "../Card";

export default function GetStarted({ coverList }) {
  const navigate = useNavigate();

  const handleSelectedCover = (id, front_img, back_img) => {
    localStorage.setItem(
      "Cover",
      JSON.stringify({
        CoverType: "predesign",
        CoverId: id,
        FrontImage: front_img,
        BackImage: back_img,
      })
    );
    navigate("/cover#FirstPage");
  };

  return (
    <>
      <Box className="getStartedContainer">
        <Box className="getStartedContainer_box">
          <Box className="getStartedContainer_title">
            <Typography className="getStartedHeaderSmall">
              How It works
            </Typography>
            <Box className="hero_video pointer">
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/play.svg"
                alt=""
              />
              <Typography>Watch a Demo Video</Typography>
            </Box>
          </Box>
          <Card />
        </Box>
      </Box>
    </>
  );
}
