import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { Box, Typography, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import ErrorMessagePara from "../../../CommonComponents/ErrorMessagePara";
import axios from "axios";
import "./style.css";
import config from "../../../config";
import { useGoogleLogin } from "@react-oauth/google";

import NavBar from "../../NavBar";
import Footer from "../../Footer";

export default function Login({ setMyUser }) {
  const navigate = useNavigate();
  const { token } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const redirect = searchParams.get("redirect");
  const act = searchParams.get("act");

  const BACKEND_URL = config.BACKEND_URL;

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [eye, setEye] = useState(false);

  const {
    register: loginForm,
    handleSubmit: loginSubmit,
    errors: loginErrors,
  } = useForm();

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    //for runn the function at page lode
    var userSession = localStorage.getItem("User");

    //condition of perticuler customer is login than it set
    if (userSession) {
      setMyUser(userSession); //we use JSON.parse for covert the string in to object
      setIsLoggedIn(true);

      if (redirect != "") {
        const redirectPath = act != "" ? redirect + "?act=" + act : redirect;
        navigate("/" + redirectPath);
      } else {
        navigate("/myaccount");
      }
    }
  }, []);

  /* ------------- Google Login Code -------------------*/
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  const loginWIthGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      localStorage.setItem("Token", tokenResponse.access_token);
      let user = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: "Bearer " + tokenResponse.access_token,
          },
        }
      );
      const GoogleuserData = await user.json();
      let userData = {
        google_login: tokenResponse.access_token,
        first_name: GoogleuserData.given_name,
        last_name: GoogleuserData.name,
        email: GoogleuserData.email,
        profile_image: GoogleuserData.picture,
        social_Id: GoogleuserData.id,
      };

      await axios
        .post(`${BACKEND_URL}/api/V1/login-with-google-auth`, userData)
        .then((res) => {
          if (res.data.status) {
            setMyUser(res.data.result.user);
            localStorage.setItem("User", JSON.stringify(res.data.result.user));
            localStorage.setItem("Token", res.data.result.token);
            localStorage.setItem("SaveDairy", res.data.result.saveDairy);
            if (redirect) {
              const redirectPath =
                act != "" ? redirect + "?act=" + act : redirect;
              navigate("/" + redirectPath);
            } else {
              navigate("/myaccount");
            }
          } else {
            if (!res.data.status && res.data.errors) {
              setErrorMessage(res.data.errors);
            }
          }
        })
        .catch((error) => {
          if (error.errors) {
            setErrorMessage(error.errors);
          }
        });
    },
  });
  /* ------------- Login Code -------------------*/
  const [loginSubmited, setLoginSubmited] = useState(false);
  const [loginPassV, setLoginPassV] = useState(false);
  const [userLoginLog, SetUserLoginLog] = useState({
    email: "",
    password: "",
  });

  const handleLoginParameter = (e) => {
    const { name, value } = e.target;

    setErrorMessage("");
    setMessage("");

    SetUserLoginLog({
      ...userLoginLog,
      [name]: value,
    });
  };

  const submitLoginForm = async (data) => {
    setLoginSubmited(true);
    if (
      data.email !== "" &&
      validateEmail(data.email) &&
      data.password !== ""
    ) {
      await axios
        .post(`${BACKEND_URL}/api/V1/login`, data)
        .then((res) => {
          if (res.data.status) {
            setMyUser(res.data.result.user); //customerlog hold the data of registered user or data of store in backend and database
            localStorage.setItem("User", JSON.stringify(res.data.result.user)); //hear we customerlog JSON.stringify for convert the object in to string
            localStorage.setItem("Token", res.data.result.token);
            localStorage.setItem("SaveDairy", res.data.result.saveDairy);

            if (redirect) {
              const redirectPath =
                act != "" ? redirect + "?act=" + act : redirect;
              navigate("/" + redirectPath);
            } else {
              navigate("/myaccount");
            }
          } else {
            if (!res.data.status && res.data.errors) {
              setErrorMessage(res.data.errors);
            }
          }
        })
        .catch((error) => {
          if (error.errors) {
            setErrorMessage(error.errors);
          }
        });
    }
  };
  /* ------------- Login Code -------------------*/

  return (
    <>
      <NavBar />
      <Box className="signUpContainer">
        <Box className="loginBox">
          <Box className="login">
            <Typography>Login</Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "40px",
              padding: "12px",
              border: "1px solid  #E6EBEB",
              position: "relative",
              width: "100%",
            }}
          >
            <Box className="googleSignUp" onClick={() => loginWIthGoogle()}>
              <img
                className="googleIcon"
                src="https://byb-svg.s3.us-west-2.amazonaws.com/GoogleIcon.svg"
                alt="GoogleIcon"
              />
              <Typography ml={1.5}>Continue with Google</Typography>
            </Box>
            <Box className="bottomText">
              <Typography>OR</Typography>
            </Box>
          </Box>

          <form
            onSubmit={loginSubmit(submitLoginForm)}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {errorMessage !== "" ? (
              <p className="text-red-600">{errorMessage}</p>
            ) : (
              ""
            )}

            <Box mb={1} className="emailInputBox">
              <Typography>Email</Typography>
              <TextField
                className="emailInput"
                name="email"
                type="email"
                {...loginForm("email", { required: true })}
                onChange={handleLoginParameter}
                required
              />
              <ErrorMessagePara errorType={loginErrors?.email?.type} />
              {!validateEmail(userLoginLog.email) &
                (userLoginLog.email !== "") && loginSubmited ? (
                <p className="text-red-600">*please enter valid Email</p>
              ) : (
                ""
              )}
            </Box>

            <Box className="passwordInputBox">
              <Box className="password_title">
                <Typography>Password</Typography>
                <a href="/forgot-password">Forgot password? </a>
              </Box>

              <TextField
                className="passwordInput"
                name="password"
                type={eye ? "text" : "password"}
                {...loginForm("password", { required: true })}
                onChange={handleLoginParameter}
                required
              />
              {eye ? (
                <img
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    right: "21px",
                    cursor: "pointer",
                  }}
                  src="https://byb-svg.s3.us-west-2.amazonaws.com/eye-password-show.svg"
                  alt=""
                  width={20}
                  height={20}
                  onClick={() => setEye(!eye)}
                />
              ) : (
                <img
                  style={{
                    position: "absolute",
                    bottom: "15px",
                    right: "21px",
                    cursor: "pointer",
                  }}
                  src="https://byb-svg.s3.us-west-2.amazonaws.com/eye-password-hide.svg"
                  alt=""
                  width={20}
                  height={20}
                  onClick={() => setEye(!eye)}
                />
              )}

              <ErrorMessagePara errorType={loginErrors?.password?.type} />
            </Box>
            <Box sx={{ width: "100%" }}>
              <button type="submit" className="singInButton pointer">
                <Typography>Login</Typography>
              </button>
              <Box
                className="CandPtab CandPRightBorder"
                sx={{ marginTop: "16px" }}
              >
                <Typography>
                  Don't have an account?{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      redirect != null
                        ? navigate(
                            "/register?redirect=" + redirect + "&act=" + act
                          )
                        : navigate("/register")
                    }
                  >
                    Sign up
                  </span>{" "}
                </Typography>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
