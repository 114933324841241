import React, { useEffect, useState } from "react";
import "./style.css";
import { Box, Card, CardContent, Typography, IconButton } from "@mui/material";

function StoriesSliderForCart({ slider, title }) {
  const [maxHeight, setMaxHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  useEffect(() => {
    const items = Array.from(document.querySelectorAll(".storiesCard"));
    let max = 0;
    items.forEach((item) => {
      const slideHeight = item.clientHeight;
      max = Math.max(max, slideHeight);
    });
    setMaxHeight(max);
  }, []);

  const handleNext = () => {
    if (isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slider.length);
      setIsSliding(false);
    }, 300);
  };

  const handlePrev = () => {
    if (isSliding) return;
    setIsSliding(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? slider.length - 1 : prevIndex - 1
      );
      setIsSliding(false);
    }, 300);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        maxWidth: 600,
        margin: "0 auto",
        overflow: "hidden",
        backgroundColor: "#2E513E",
        borderRadius: "12px",
      }}
      className="rewiewBox"
    >
      <Box
        sx={{
          padding: "20px 32px",
          borderBottom: "2px solid #033333",
          display: "flex",
          justifyContent: "space-between ",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            color: "white",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "21.6px",
            fontFamily: "Poppins",
          }}
        >
          What they’re saying
        </Typography>
        <Box sx={{}}>
          <IconButton
            onClick={handlePrev}
            sx={{
              color: "white",

              "&:hover": { backgroundColor: "#2a4d40" },
            }}
          >
            <img
              src="https://byb-svg.s3.us-west-2.amazonaws.com/left.svg"
              alt=""
            />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              color: "white",
              "&:hover": { backgroundColor: "#2a4d40" },
            }}
          >
            <img
              src="https://byb-svg.s3.us-west-2.amazonaws.com/right.svg"
              alt=""
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          transform: `translateX(${-currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {slider.map((testimonial, index) => (
          <Card
            key={index}
            sx={{
              color: "white",
              borderRadius: 2,
              padding: "20px 32px",
              minHeight: "180px",
              width: "100%",
              flexShrink: 0,
              backgroundColor: "#2E513E",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "white",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18.9px",
                fontFamily: "Poppins",
              }}
            >
              {testimonial.comments}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "16.2px",
                  color: "#F8F4EB",
                }}
              >
                {testimonial.user_name}
              </Typography>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                {Array.from({ length: 5 }, (_, i) => (
                  <StarIcon key={i} sx={{ color: "#ffc107" }} />
                ))}
              </Box> */}
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

export default StoriesSliderForCart;
