import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import config from "../../config";
import "./style.css";
import { ToastContainer } from "react-toastify";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

export default function PaymentForm({
  callFuncion,
  newCartSubTotal,
  shipAmount,
  newTax,
  handleDeleteItem,
  isChecked,
  isValid,
  email,
}) {
  const initialOptions = {
    clientId:
      // "Ad-OolkrYST_44CF3IA7AiK_8TA_dTs4MAzj8MS6c3SZzK3a6Jsr1hxANRgYGcKfaBBCC_L06xRC2D2C",
      "AXgVDF2d8eaVz34LMH2MbZozPmFeUoOcePzgNz8wX4KrdbeCSzA-4PuSrUlz6cGibKhtr7v1bZ5jOUld",
    locale: "en_US",
  };

  const BACKEND_URL = config.BACKEND_URL;

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const userSession = localStorage.getItem("User");
  const apiToken = localStorage.getItem("Token");

  const ApiHeaders = {
    Authorization: `Bearer ${apiToken}`,
    "Content-Type": "application/json", // Include this header if needed
  };

  const [myUser, setMyUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [cartID, setCartID] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [paymentSuccess, setPaymentSuccess] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [cartCoupon, setCartCoupon] = useState("");
  const [discountAmt, setDiscountAmt] = useState(0);
  const [tax, setTax] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [subscribeEmailError, setSubscribeEmailError] = useState("");
  const [subscribeEmail, setSubscribeEmail] = useState(false);
  const [saveChecked, setSaveChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setCartSubTotal(newCartSubTotal);
    setShippingCost(shipAmount);
    setTax(newTax);
    setCartTotal(newCartSubTotal + shipAmount + newTax);
  }, [newCartSubTotal, shipAmount, newTax]);

  useEffect(() => {
    if (userSession !== undefined && userSession !== null) {
      const userSessionData = JSON.parse(userSession);
      setMyUser(userSessionData);
      setIsLoggedIn(true);
    }

    var CartSessionData = JSON.parse(localStorage.getItem("Cart"));
    if (CartSessionData !== undefined && CartSessionData !== null) {
      setCartID(CartSessionData.CartID);

      axios
        .get(`${BACKEND_URL}/api/V1/getCart/${CartSessionData.CartID}`, {
          headers: ApiHeaders,
        })
        .then((response) => {
          const { Cart, CartItems } = response.data.result;
          setCartData(Cart);
          setCartItems(CartItems);
          setCartSubTotal(Cart.subtotal);
          setCartCoupon(Cart.coupon);
          setDiscountAmt(Cart.discount_amount);
          setTax(Cart.tax);
          setShippingCost(Cart.shipping_cost);
          setCartTotal(Cart.total);
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      navigate("/");
    }
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(price);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    await callFuncion().then(async (res) => {
      try {
        // const { token, error } = await stripe.createToken(
        //   elements.getElement(CardElement),
        //   //   elements.getElement(PaymentElement),
        //   {
        //     disableLink: true,
        //   }
        // );
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // return_url: `http://localhost:3000/cart`,
          },
          redirect: "if_required",
        });

        if (error) {
          console.error(error);
          setPaymentError(error.message);
        } else {
          if (!res && termsChecked) {
            await axios
              .post(
                `${BACKEND_URL}/api/V1/process-payment`,
                {
                  type: "stripe",
                  CartID: cartID,
                  token: paymentIntent,
                  user_id: myUser.id,
                },
                { headers: ApiHeaders }
              )
              .then((response) => {
                if (response.data.status) {
                  navigate("/");
                } else {
                  setPaymentError(response.data.errors);
                  navigate("/payment-failed");
                }
              })
              .catch((error) => {
                setPaymentError(error);
                navigate("/payment-failed");
                console.error("Error fetching layout data:", error);
              });

            if (subscribeEmail) {
              await axios
                .post(`${BACKEND_URL}/api/V1/subscribe/`, { email })
                .then((response) => {
                  const { message } = response.data.result;
                  setSubscribeEmail(false);
                })
                .catch((error) => {
                  console.error("Error fetching layout data:", error);
                });
            } else {
              setSubscribeEmailError("Please enter a valid email address.");
            }
          }
        }
      } catch (error) {
        console.error(error);
        setPaymentError(error.message);
      }
    });
  };

  function handleApprove(details) {
    // Custom logic for handling successful payment

    axios
      .post(
        `${BACKEND_URL}/api/V1/process-payment`,
        {
          CartID: cartID,
          order_id: details.id,
        },
        { headers: ApiHeaders }
      )
      .then((response) => {
        if (response.data.status) {
          navigate("/order-success");
        } else {
          setPaymentError(response.data.errors);

          navigate("/payment-failed");
        }
      })
      .catch((error) => {
        console.error("Error creating order:", error);
      });
  }
  const handlePaymentChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  return (
    <>
      <Box className="paymentContainer">
        <Box className="paymentBoxOuter ">
          <Box className="paymentBox">
            <Typography className="sectionHeader">Payment</Typography>
            <Box sx={{ width: "100%" }}>
              <FormControl component="fieldset" sx={{ width: "100%" }}>
                <RadioGroup
                  value={selectedPaymentMethod}
                  onChange={handlePaymentChange}
                  sx={{ display: "flex", gap: "8px" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor:
                        selectedPaymentMethod === "paypal"
                          ? "white"
                          : "#F2F5F5",
                      padding: "16px",
                      borderRadius: "12px",
                      border: "1px solid #CDD6D6",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          gap: "14.5px",
                        }}
                      >
                        <FormControlLabel
                          value="paypal"
                          className="radio_label"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2E513E",
                                },
                              }}
                            />
                          }
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                              lineHeight: "21.6px",
                              color: "#293D3D",
                            }}
                          >
                            PayPal
                          </Typography>

                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "18.9px",
                              color: "#688585",
                            }}
                          >
                            Pay with your PayPal account.
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          padding: "6.25px 12.15px",
                          backgroundColor:
                            selectedPaymentMethod === "paypal"
                              ? "white"
                              : "#E6EBEB",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://byb-svg.s3.us-west-2.amazonaws.com/paypal.svg"
                          alt=""
                        />
                      </Box>
                    </Box>

                    {selectedPaymentMethod === "paypal" && (
                      <div style={{ marginTop: "20px" }}>
                        <Box className="StripeFormButton">
                          {cartTotal > 0 && (
                            <PayPalScriptProvider options={initialOptions}>
                              <PayPalButtons
                                style={{
                                  disableMaxWidth: true,
                                  size: "small",
                                  height: 40,
                                  layout: "vertical",
                                }}
                                createOrder={(data, actions) => {
                                  return actions.order.create({
                                    purchase_units: [
                                      {
                                        amount: {
                                          currency_code: "USD",
                                          value: cartTotal, // Set the amount to 100 USD
                                        },
                                      },
                                    ],
                                  });
                                }}
                                onApprove={(data, actions) => {
                                  return actions.order
                                    .capture()
                                    .then(handleApprove);
                                }}
                              />
                            </PayPalScriptProvider>
                          )}
                        </Box>
                      </div>
                    )}
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor:
                        selectedPaymentMethod === "credit-card"
                          ? "white"
                          : "#F2F5F5",
                      padding: "16px",
                      borderRadius: "12px",
                      border: "1px solid #CDD6D6",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          gap: "14.5px",
                        }}
                      >
                        <FormControlLabel
                          value="credit-card"
                          className="radio_label"
                          control={
                            <Radio
                              sx={{
                                "&.Mui-checked": {
                                  color: "#2E513E",
                                },
                              }}
                            />
                          }
                          sx={{
                            backgroundColor:
                              selectedPaymentMethod === "credit-card"
                                ? "white"
                                : "#F2F5F5",
                          }}
                        />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                              lineHeight: "21.6px",
                              color: "#293D3D",
                            }}
                          >
                            Credit Card
                          </Typography>

                          <Typography
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "18.9px",
                              color: "#688585",
                            }}
                          >
                            Pay with your credit card via Stripe.
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          padding: "6.25px 12.15px",
                          backgroundColor:
                            selectedPaymentMethod === "credit-card"
                              ? "white"
                              : "#E6EBEB",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src="https://byb-svg.s3.us-west-2.amazonaws.com/card.svg"
                          alt=""
                        />
                      </Box>
                    </Box>

                    {selectedPaymentMethod === "credit-card" && (
                      <div style={{ marginTop: "20px" }}>
                        <form className="StripeForm" onSubmit={handleSubmit}>
                          {paymentError !== "" ? (
                            <Typography
                              sx={{ marginBottom: "20px" }}
                              className="ErrMsg"
                            >
                              {paymentError}
                            </Typography>
                          ) : (
                            ""
                          )}
                          {/* <CardElement /> */}
                          <PaymentElement />

                          <Box sx={{ margin: "10px 0 20px 0" }}>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                color: "#697777",
                                margin: " 0 4px",
                              }}
                            >
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in
                              our Privacy Policy.
                            </Typography>
                          </Box>
                          {!isChecked || !termsChecked ? (
                            <Typography className="ErrMsg">
                              Please accept the terms.
                            </Typography>
                          ) : (
                            ""
                          )}
                          <Box className="payCheck">
                            <input
                              onClick={() => setSaveChecked(!saveChecked)}
                              checked={saveChecked}
                              type="checkbox"
                            />
                            <Typography ml={1}>
                              Save payment information to my account for future
                              purchases.
                            </Typography>
                          </Box>
                          <Box className="payCheck">
                            <input
                              onClick={() => setTermsChecked(!termsChecked)}
                              checked={termsChecked}
                              type="checkbox"
                            />
                            <Typography ml={1}>
                              I have read and agree to the event{" "}
                              <Link to="/terms-conditions">
                                Terms and Conditions *
                              </Link>
                               
                            </Typography>
                          </Box>
                          <Box className="payCheck">
                            <input
                              onChange={(e) => {
                                setSubscribeEmail(e.target.value);
                                setSubscribeEmail(!subscribeEmail);
                              }}
                              checked={subscribeEmail}
                              type="checkbox"
                            />
                            <Typography ml={1}>
                              Subscribe to news, updates and notify me about new
                              promotions.
                            </Typography>
                          </Box>

                          <button
                            disabled={!stripe}
                            className="button"
                            type="submit"
                          >
                            Place order now
                          </button>
                        </form>
                      </div>
                    )}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            {/* <Box className="cartSubHeader">
              <p className="cartHeaderORText"> OR </p>
            </Box> */}
          </Box>
        </Box>
      </Box>
      {/* <Footer /> */}
      <ToastContainer autoClose={false} draggable={false} />
    </>
  );
}
