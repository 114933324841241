import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import config from "../../config";
import PriceBox from "../../Components/PriceBox";
import ReactGA from "react-ga4";
import "./style.css";

import NavHeaderImg from "../../Assets/images/BYBnewtag(white)-PLANNER.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseMenuIcon from "@mui/icons-material/Close";

import { ButtonPrimary } from "../../Components/Buttons";
const apiToken = localStorage.getItem("Token");
const ApiHeaders = {
  Authorization: `Bearer ${apiToken}`,
  "Content-Type": "application/json", // Include this header if needed
};

export default function NavBar({
  nextUrl,
  updatePriceBox,
  updatePageCount,
  addBtnFunc,
  updateNavBar,
}) {
  ReactGA.initialize("G-JQ0HP4B7Y5");
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const hashValue = location.hash.replace("#", "");
  const menuRef = useRef(null);

  var SaveDairy = localStorage.getItem("SaveDairy");
  var CoverData = JSON.parse(localStorage.getItem("Cover"));
  var FirstPageData = JSON.parse(localStorage.getItem("FirstPage"));
  var LayoutData = JSON.parse(localStorage.getItem("Layout"));
  var CalendarData = JSON.parse(localStorage.getItem("Calendar"));
  var AddinsData = JSON.parse(localStorage.getItem("Addins"));
  var DatesData = JSON.parse(localStorage.getItem("Dates"));
  var PriceData = JSON.parse(localStorage.getItem("Price"));
  var ReviewData = JSON.parse(localStorage.getItem("Review"));

  const searchParams = new URLSearchParams(location.search);
  const act = searchParams.get("act");

  const BACKEND_URL = config.BACKEND_URL;

  const [mobileNav, setMobileNav] = useState(false);
  const [myUser, setMyUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [nextBtnEnable, setNextBtnEnable] = useState(false);
  const [priceBox, setPriceBox] = useState(1);
  const [itemCount, setItemCount] = useState();

  const PriceBoxUrl = [
    "/cover",
    "/firstpage",
    "/layout",
    "/addins",
    "/dates",
    "/review",
  ];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    var userSession = localStorage.getItem("User");
    if (userSession != undefined) {
      userSession = JSON.parse(userSession);
      setMyUser(userSession);
      setIsLoggedIn(true);
    }

    if (act == "saveprogress") {
      saveProgressFunc();
    }
  }, []);

  console.log("Is userlogged in:", isLoggedIn);

  useEffect(() => {
    var CartSessionData = JSON.parse(localStorage.getItem("Cart"));
    if (CartSessionData !== undefined && CartSessionData !== null) {
      axios
        .get(`${BACKEND_URL}/api/V1/getCart/${CartSessionData.CartID}`, {
          headers: ApiHeaders,
        })
        .then((response) => {
          const { Cart, CartItems } = response.data.result;
          setItemCount(CartItems.length);
          console.log("======Cart====:", Cart);
          if (Cart.payment_status == 1) {
            setItemCount(0);
          } else {
            setItemCount(itemCount++);
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    }
  }, [updateNavBar]);

  const handleLogin = () => {
    const currentUrl2 = currentRoute.replace("/", "");
    window.location.href = `/login?redirect=${currentUrl2}`;
  };

  const handleSaveProgress = () => {
    if (isLoggedIn) {
      saveProgressFunc();
    } else {
      const currentUrl2 = currentRoute.replace("/", "");
      window.location.href = `/login?redirect=${currentUrl2}&act=saveprogress`;
    }
  };

  const saveProgressFunc = () => {
    //getting session data
    var SaveDiaryId = localStorage.getItem("SaveDiaryId");
    var CoverData = localStorage.getItem("Cover");
    var FirstPageData = localStorage.getItem("FirstPage");
    var LayoutData = localStorage.getItem("Layout");
    var CalendarData = localStorage.getItem("Calendar");
    var AddinsData = localStorage.getItem("Addins");
    var DatesData = localStorage.getItem("Dates");
    var PriceData = localStorage.getItem("Price");

    const apiToken = localStorage.getItem("Token");
    const postData = {
      SaveDiaryId: SaveDiaryId,
      Cover: CoverData,
      FirstPage: FirstPageData,
      Layout: LayoutData,
      Calendar: CalendarData,
      Addins: AddinsData,
      Dates: DatesData,
      PriceData: PriceData,
    };
    const headers = {
      Authorization: `Bearer ${apiToken}`,
      "Content-Type": "application/json", // Include this header if needed
    };

    axios
      .post(`${BACKEND_URL}/api/V1/saveProgress`, postData, {
        headers: headers,
      })
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("SaveDiaryId", response.data.result.saveId);
        } else {
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleLogout = () => {
    setMyUser("");
    setIsLoggedIn(false);
    localStorage.removeItem("User");
    localStorage.removeItem("Token");
    localStorage.removeItem("SaveDairy");
    localStorage.removeItem("Cover");
    localStorage.removeItem("FirstPage");
    localStorage.removeItem("Layout");
    localStorage.removeItem("Calendar");
    localStorage.removeItem("Addins");
    localStorage.removeItem("Dates");
    localStorage.removeItem("Price");
    localStorage.removeItem("SaveDiaryId");
    localStorage.removeItem("Cart");
    navigate("/");
  };

  const previousNavigate = () => {
    if (currentRoute == "/cover") {
      setNextBtnEnable(true);
      navigate("/");
    }
    if (currentRoute == "/firstpage") {
      setNextBtnEnable(true);
      navigate("/cover");
    }
    if (currentRoute == "/layout") {
      setNextBtnEnable(true);
      navigate("/firstpage");
    }
    if (currentRoute == "/addins") {
      setNextBtnEnable(true);
      navigate("/layout");
    }
    if (currentRoute == "/dates") {
      setNextBtnEnable(true);
      navigate("/addins");
    }
    if (currentRoute == "/review") {
      setNextBtnEnable(true);
      navigate("/dates");
    }
    if (currentRoute == "/cart") {
      setNextBtnEnable(true);
      navigate("/review");
    }
  };

  const nextNavigate = () => {
    setNextBtnEnable(false);
    if (currentRoute == "/") {
      setNextBtnEnable(true);
      navigate("/cover");
    }
    if (currentRoute == "/cover" && CoverData !== undefined) {
      setNextBtnEnable(true);
      navigate("/firstpage");
    }
    if (currentRoute == "/firstpage" && FirstPageData !== undefined) {
      setNextBtnEnable(true);
      addBtnFunc();
      //navigate('/layout');
    }
    if (currentRoute == "/layout" && LayoutData !== undefined) {
      setNextBtnEnable(true);
      navigate("/addins");
    }
    if (currentRoute == "/addins" && AddinsData !== undefined) {
      setNextBtnEnable(true);
      navigate("/dates");
    }
    if (
      currentRoute == "/dates" &&
      DatesData !== undefined &&
      PriceData.pageCount < PriceData.maxPageCount
    ) {
      setNextBtnEnable(true);
      addBtnFunc();
    }
    if (
      currentRoute == "/review" &&
      ReviewData !== undefined &&
      ReviewData !== null &&
      ReviewData.TermsAgree == false
    ) {
      setNextBtnEnable(false);
    }
    if (
      currentRoute == "/review" &&
      ReviewData !== undefined &&
      ReviewData !== null &&
      ReviewData.TermsAgree == true
    ) {
      setNextBtnEnable(true);
      navigate("/cart");
    }
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMobileNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDrawer = (open) => () => {
    setMobileNav(open);
  };

  return (
    <>
      <Box className="navBar">
        {mobileNav && (
          <Box className="hamburger_menu" ref={menuRef}>
            <Box sx={{ display: "flex", alignItems: "end", gap: "60px" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Box
                  className={
                    currentRoute === "/" ? "menu_items" : "menu_items_unFocuse"
                  }
                  onClick={() => navigate("/")}
                >
                  <Typography>Home</Typography>
                </Box>
                <Box
                  className={
                    currentRoute === "/cover"
                      ? "menu_items"
                      : "menu_items_unFocuse"
                  }
                  onClick={() => navigate("/cover")}
                >
                  <Typography>Cover</Typography>
                </Box>
                <Box
                  onClick={() => navigate("/firstpage")}
                  className={
                    currentRoute === "/firstpage"
                      ? "menu_items"
                      : "menu_items_unFocuse"
                  }
                >
                  <Typography>First Page</Typography>
                </Box>
                <Box
                  className={
                    currentRoute === "/layout"
                      ? "menu_items"
                      : "menu_items_unFocuse"
                  }
                  onClick={() => navigate("/layout")}
                >
                  <Typography>Layout</Typography>
                </Box>
                <Box
                  className={
                    currentRoute === "/addins"
                      ? "menu_items"
                      : "menu_items_unFocuse"
                  }
                  onClick={() => navigate("/addins")}
                >
                  <Typography>Add-Ins</Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
              >
                <Box
                  className={
                    currentRoute === "/dates"
                      ? "menu_items2"
                      : "menu_items2_unFocuse"
                  }
                  onClick={() => navigate("/dates")}
                >
                  <Typography>Dates</Typography>
                </Box>
                <Box
                  className={
                    currentRoute === "/review"
                      ? "menu_items2"
                      : "menu_items2_unFocuse"
                  }
                  onClick={() => navigate("/review")}
                >
                  <Typography>Review</Typography>
                </Box>
                <Box
                  className={
                    currentRoute === "/cart"
                      ? "menu_items2"
                      : "menu_items2_unFocuse"
                  }
                  onClick={() => navigate("/cart")}
                >
                  <Typography>Cart</Typography>
                  {itemCount > 0 && <Box className="cartcnt2">{itemCount}</Box>}
                </Box>
                <Box
                  c
                  className={
                    currentRoute === "/store"
                      ? "menu_items2"
                      : "menu_items2_unFocuse"
                  }
                  onClick={() => navigate("/store")}
                >
                  <Typography>Store</Typography>
                </Box>
              </Box>
            </Box>
            {isLoggedIn && (
              <Box className="UserInfoBox hidemobile">
                {/* <Typography className="UserInfo">{myUser.first_name}</Typography> */}
                <Box className="myAcc" onClick={() => navigate("/myaccount")}>
                  <button>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "2px",
                      }}
                    >
                      <svg
                        width="16"
                        height="15"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00026 7C7.00446 7 6.18259 6.67611 5.53466 6.02833C4.88657 5.38056 4.56253 4.55869 4.56253 3.56273C4.56253 2.56692 4.88642 1.74506 5.5342 1.09712C6.18198 0.449041 7.00385 0.125 7.9998 0.125C8.99561 0.125 9.81748 0.448889 10.4654 1.09667C11.1135 1.74445 11.4375 2.56631 11.4375 3.56227C11.4375 4.55808 11.1136 5.37994 10.4659 6.02788C9.81809 6.67596 8.99622 7 8.00026 7ZM0.804199 14.1346V11.9346C0.804199 11.3822 0.94231 10.9023 1.21853 10.4948C1.49491 10.0873 1.85684 9.77704 2.30432 9.56392C3.29035 9.11246 4.24995 8.77153 5.18312 8.54115C6.11644 8.31076 7.05541 8.19556 8.00003 8.19556C8.94466 8.19556 9.88103 8.31206 10.8092 8.54504C11.7373 8.77803 12.6948 9.11949 13.6818 9.56942C14.1409 9.78224 14.508 10.0919 14.7831 10.4985C15.0583 10.905 15.1959 11.385 15.1959 11.9383V14.1346H0.804199ZM2.25551 12.6833H13.7446V11.9367C13.7446 11.7135 13.6808 11.504 13.5534 11.3081C13.4262 11.1122 13.2696 10.9659 13.0836 10.869C12.1593 10.4235 11.2942 10.1084 10.4883 9.92371C9.68227 9.73915 8.85353 9.64688 8.0021 9.64688C7.14516 9.64688 6.31054 9.73915 5.49822 9.92371C4.68605 10.1084 3.82286 10.4235 2.90864 10.869C2.71766 10.9659 2.56107 11.1126 2.43885 11.3093C2.31662 11.5059 2.25551 11.7156 2.25551 11.9383V12.6833ZM8.00003 5.54869C8.56531 5.54869 9.03762 5.35894 9.41697 4.97944C9.79647 4.60009 9.98622 4.12778 9.98622 3.5625C9.98622 2.99722 9.79647 2.52491 9.41697 2.14556C9.03762 1.76606 8.56531 1.57631 8.00003 1.57631C7.43475 1.57631 6.96244 1.76606 6.5831 2.14556C6.2036 2.52491 6.01384 2.99722 6.01384 3.5625C6.01384 4.12778 6.2036 4.60009 6.5831 4.97944C6.96244 5.35894 7.43475 5.54869 8.00003 5.54869Z"
                          fill="#033333"
                        />
                      </svg>
                    </Box>
                    <Box>{myUser.first_name}</Box>
                  </button>
                  {/* <ButtonPrimary buttonText={myUser.first_name} /> */}
                </Box>
                <Box className="LogoutBtn" onClick={handleLogout}>
                  <ButtonPrimary buttonText="Logout" />
                </Box>
              </Box>
            )}
            {!isLoggedIn ? (
              <Box className="login_title_mobile" onClick={handleLogin}>
                <Typography>Login</Typography>
              </Box>
            ) : (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "21px" }}
              >
                <Box
                  className="login_title_mobile"
                  onClick={() => navigate("/myaccount")}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2px",
                    }}
                  >
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00026 7C7.00446 7 6.18259 6.67611 5.53466 6.02833C4.88657 5.38056 4.56253 4.55869 4.56253 3.56273C4.56253 2.56692 4.88642 1.74506 5.5342 1.09712C6.18198 0.449041 7.00385 0.125 7.9998 0.125C8.99561 0.125 9.81748 0.448889 10.4654 1.09667C11.1135 1.74445 11.4375 2.56631 11.4375 3.56227C11.4375 4.55808 11.1136 5.37994 10.4659 6.02788C9.81809 6.67596 8.99622 7 8.00026 7ZM0.804199 14.1346V11.9346C0.804199 11.3822 0.94231 10.9023 1.21853 10.4948C1.49491 10.0873 1.85684 9.77704 2.30432 9.56392C3.29035 9.11246 4.24995 8.77153 5.18312 8.54115C6.11644 8.31076 7.05541 8.19556 8.00003 8.19556C8.94466 8.19556 9.88103 8.31206 10.8092 8.54504C11.7373 8.77803 12.6948 9.11949 13.6818 9.56942C14.1409 9.78224 14.508 10.0919 14.7831 10.4985C15.0583 10.905 15.1959 11.385 15.1959 11.9383V14.1346H0.804199ZM2.25551 12.6833H13.7446V11.9367C13.7446 11.7135 13.6808 11.504 13.5534 11.3081C13.4262 11.1122 13.2696 10.9659 13.0836 10.869C12.1593 10.4235 11.2942 10.1084 10.4883 9.92371C9.68227 9.73915 8.85353 9.64688 8.0021 9.64688C7.14516 9.64688 6.31054 9.73915 5.49822 9.92371C4.68605 10.1084 3.82286 10.4235 2.90864 10.869C2.71766 10.9659 2.56107 11.1126 2.43885 11.3093C2.31662 11.5059 2.25551 11.7156 2.25551 11.9383V12.6833ZM8.00003 5.54869C8.56531 5.54869 9.03762 5.35894 9.41697 4.97944C9.79647 4.60009 9.98622 4.12778 9.98622 3.5625C9.98622 2.99722 9.79647 2.52491 9.41697 2.14556C9.03762 1.76606 8.56531 1.57631 8.00003 1.57631C7.43475 1.57631 6.96244 1.76606 6.5831 2.14556C6.2036 2.52491 6.01384 2.99722 6.01384 3.5625C6.01384 4.12778 6.2036 4.60009 6.5831 4.97944C6.96244 5.35894 7.43475 5.54869 8.00003 5.54869Z"
                        fill="#033333"
                      />
                    </svg>
                  </Box>
                  <Typography>{myUser.first_name}</Typography>
                </Box>
                <Box className="LogoutBtn_mobile" onClick={handleLogout}>
                  <ButtonPrimary buttonText="Logout" />
                </Box>
              </Box>
            )}
          </Box>
        )}

        {!mobileNav && (
          <Box onClick={toggleDrawer(true)} className="hamburgerMenu">
            <MenuIcon fontSize="large" />
          </Box>
        )}
        {mobileNav && (
          <Box onClick={toggleDrawer(false)} className="hamburgerMenu">
            <CloseMenuIcon fontSize="large" />
          </Box>
        )}

        <Box className="navTopTextBox pointer">
          <Box onClick={() => navigate("/")} className="logo">
            <img className="navTopImg" src={NavHeaderImg} />
          </Box>
        </Box>

        <Box className="carticon_Mobile">
          <svg
            width="19"
            height="20"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.96146 16.9648C4.5566 16.9648 4.21236 16.8229 3.92875 16.5392C3.64514 16.2556 3.50333 15.9113 3.50333 15.5065C3.50333 15.1015 3.64514 14.7572 3.92875 14.4735C4.21236 14.1899 4.5566 14.0481 4.96146 14.0481C5.36646 14.0481 5.71076 14.1899 5.99438 14.4735C6.27799 14.7572 6.41979 15.1015 6.41979 15.5065C6.41979 15.9113 6.27799 16.2556 5.99438 16.5392C5.71076 16.8229 5.36646 16.9648 4.96146 16.9648ZM13.0385 16.9648C12.6335 16.9648 12.2892 16.8229 12.0056 16.5392C11.722 16.2556 11.5802 15.9113 11.5802 15.5065C11.5802 15.1015 11.722 14.7572 12.0056 14.4735C12.2892 14.1899 12.6335 14.0481 13.0385 14.0481C13.4434 14.0481 13.7876 14.1899 14.0713 14.4735C14.3549 14.7572 14.4967 15.1015 14.4967 15.5065C14.4967 15.9113 14.3549 16.2556 14.0713 16.5392C13.7876 16.8229 13.4434 16.9648 13.0385 16.9648ZM4.01271 3.79167L6.125 8.21479H11.806C11.8541 8.21479 11.8969 8.20278 11.9344 8.17875C11.9717 8.15472 12.0038 8.12132 12.0304 8.07854L14.266 4.01604C14.2981 3.95729 14.3008 3.90521 14.274 3.85979C14.2473 3.81438 14.2019 3.79167 14.1377 3.79167H4.01271ZM3.41354 2.54167H14.984C15.3248 2.54167 15.5825 2.68674 15.7571 2.97688C15.9318 3.26688 15.9401 3.56306 15.7821 3.86542L13.1121 8.70188C12.9754 8.94229 12.7947 9.12951 12.5698 9.26354C12.3448 9.39771 12.0983 9.46479 11.8302 9.46479H5.75L4.78521 11.2275C4.74243 11.2917 4.74111 11.3611 4.78125 11.4358C4.82125 11.5107 4.88132 11.5481 4.96146 11.5481H14.4967V12.7981H4.96146C4.4059 12.7981 3.98847 12.5585 3.70917 12.0794C3.42972 11.6002 3.41986 11.1218 3.67958 10.6442L4.86854 8.50646L1.83667 2.125H0.25V0.875H2.62188L3.41354 2.54167Z"
              fill="white"
            />
          </svg>

          {itemCount > 0 && <Box className="cartcnt">{itemCount}</Box>}
        </Box>

        <Box className="navItems">
          {/* <Box className="navItem hidemobile backarrow">
            <Typography
              className="navFont"
              onClick={() => previousNavigate()}
            >{`<`}</Typography>
          </Box> */}
          <Box className="navItem">
            <Typography
              className={currentRoute === "/" ? "navFont" : null}
              onClick={() => navigate("/")}
            >
              Home
            </Typography>
          </Box>
          <Box className="navItem">
            <Typography
              className={currentRoute === "/cover" ? "navFont" : null}
              onClick={() => navigate("/cover")}
            >
              Cover
            </Typography>
          </Box>
          <Box className="navItem large">
            <Typography
              className={currentRoute === "/firstpage" ? "navFont" : null}
              onClick={() => navigate("/firstpage")}
            >
              First Page
            </Typography>
          </Box>
          <Box className="navItem">
            <Typography
              className={currentRoute === "/layout" ? "navFont" : null}
              onClick={() => navigate("/layout")}
            >
              Layout
            </Typography>
          </Box>
          <Box className="navItem large">
            <Typography
              className={currentRoute === "/addins" ? "navFont" : null}
              onClick={() => navigate("/addins")}
            >
              Add-Ins
            </Typography>
          </Box>
          <Box className="navItem">
            <Typography
              className={currentRoute === "/dates" ? "navFont" : null}
              onClick={() => navigate("/dates")}
            >
              Dates
            </Typography>
          </Box>
          <Box className="navItem">
            <Typography
              className={currentRoute === "/review" ? "navFont" : null}
              onClick={() => navigate("/review")}
            >
              Review
            </Typography>
          </Box>

          <Box className="navItem cart">
            <Typography
              className={currentRoute === "/cart" ? "navFont" : null}
              onClick={() => navigate("/cart")}
            >
              Cart
            </Typography>
            {itemCount > 0 && <Box className="cartcnt2">{itemCount}</Box>}
          </Box>
          <Box className="navItem">
            <Typography
              className={currentRoute === "/store" ? "navFont" : null}
              onClick={() => navigate("/store")}
            >
              Store
            </Typography>
          </Box>

          {/* <Box className="navItem hidemobile nextarrow">
            <Typography
              className={nextBtnEnable ? `navFont` : `navFont disabled`}
              onClick={() => nextNavigate()}
            >{`>`}</Typography>
          </Box> */}
        </Box>
        <Box className="login_Box">
          <Box className="mobileDesignbtn">
            {!isLoggedIn && (
              <Box
                className="SaveProgressBtn hidedesktop"
                onClick={handleLogin}
              >
                <ButtonPrimary buttonText="Login" />
              </Box>
            )}
            {isLoggedIn && SaveDairy !== undefined && (
              <Box
                className="SaveProgressBtn hidedesktop"
                onClick={() => navigate("/myaccount")}
              >
                <ButtonPrimary buttonText="Load Design" />
              </Box>
            )}
          </Box>

          <Box className="loginCartTab" onClick={handleLogin}>
            <Box className="carticon">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.96146 16.9648C4.5566 16.9648 4.21236 16.8229 3.92875 16.5392C3.64514 16.2556 3.50333 15.9113 3.50333 15.5065C3.50333 15.1015 3.64514 14.7572 3.92875 14.4735C4.21236 14.1899 4.5566 14.0481 4.96146 14.0481C5.36646 14.0481 5.71076 14.1899 5.99438 14.4735C6.27799 14.7572 6.41979 15.1015 6.41979 15.5065C6.41979 15.9113 6.27799 16.2556 5.99438 16.5392C5.71076 16.8229 5.36646 16.9648 4.96146 16.9648ZM13.0385 16.9648C12.6335 16.9648 12.2892 16.8229 12.0056 16.5392C11.722 16.2556 11.5802 15.9113 11.5802 15.5065C11.5802 15.1015 11.722 14.7572 12.0056 14.4735C12.2892 14.1899 12.6335 14.0481 13.0385 14.0481C13.4434 14.0481 13.7876 14.1899 14.0713 14.4735C14.3549 14.7572 14.4967 15.1015 14.4967 15.5065C14.4967 15.9113 14.3549 16.2556 14.0713 16.5392C13.7876 16.8229 13.4434 16.9648 13.0385 16.9648ZM4.01271 3.79167L6.125 8.21479H11.806C11.8541 8.21479 11.8969 8.20278 11.9344 8.17875C11.9717 8.15472 12.0038 8.12132 12.0304 8.07854L14.266 4.01604C14.2981 3.95729 14.3008 3.90521 14.274 3.85979C14.2473 3.81438 14.2019 3.79167 14.1377 3.79167H4.01271ZM3.41354 2.54167H14.984C15.3248 2.54167 15.5825 2.68674 15.7571 2.97688C15.9318 3.26688 15.9401 3.56306 15.7821 3.86542L13.1121 8.70188C12.9754 8.94229 12.7947 9.12951 12.5698 9.26354C12.3448 9.39771 12.0983 9.46479 11.8302 9.46479H5.75L4.78521 11.2275C4.74243 11.2917 4.74111 11.3611 4.78125 11.4358C4.82125 11.5107 4.88132 11.5481 4.96146 11.5481H14.4967V12.7981H4.96146C4.4059 12.7981 3.98847 12.5585 3.70917 12.0794C3.42972 11.6002 3.41986 11.1218 3.67958 10.6442L4.86854 8.50646L1.83667 2.125H0.25V0.875H2.62188L3.41354 2.54167Z"
                  fill="white"
                />
              </svg>

              {itemCount > 0 && <Box className="cartcnt">{itemCount}</Box>}
            </Box>
            {!isLoggedIn && (
              <Box className="login-title">
                <Typography>Login</Typography>
              </Box>
            )}
          </Box>

          {isLoggedIn && (
            <Box className="UserInfoBox hidemobile">
              {/* <Typography className="UserInfo">{myUser.first_name}</Typography> */}
              <Box className="myAcc" onClick={() => navigate("/myaccount")}>
                <button>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2px",
                    }}
                  >
                    <svg
                      width="16"
                      height="15"
                      viewBox="0 0 16 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00026 7C7.00446 7 6.18259 6.67611 5.53466 6.02833C4.88657 5.38056 4.56253 4.55869 4.56253 3.56273C4.56253 2.56692 4.88642 1.74506 5.5342 1.09712C6.18198 0.449041 7.00385 0.125 7.9998 0.125C8.99561 0.125 9.81748 0.448889 10.4654 1.09667C11.1135 1.74445 11.4375 2.56631 11.4375 3.56227C11.4375 4.55808 11.1136 5.37994 10.4659 6.02788C9.81809 6.67596 8.99622 7 8.00026 7ZM0.804199 14.1346V11.9346C0.804199 11.3822 0.94231 10.9023 1.21853 10.4948C1.49491 10.0873 1.85684 9.77704 2.30432 9.56392C3.29035 9.11246 4.24995 8.77153 5.18312 8.54115C6.11644 8.31076 7.05541 8.19556 8.00003 8.19556C8.94466 8.19556 9.88103 8.31206 10.8092 8.54504C11.7373 8.77803 12.6948 9.11949 13.6818 9.56942C14.1409 9.78224 14.508 10.0919 14.7831 10.4985C15.0583 10.905 15.1959 11.385 15.1959 11.9383V14.1346H0.804199ZM2.25551 12.6833H13.7446V11.9367C13.7446 11.7135 13.6808 11.504 13.5534 11.3081C13.4262 11.1122 13.2696 10.9659 13.0836 10.869C12.1593 10.4235 11.2942 10.1084 10.4883 9.92371C9.68227 9.73915 8.85353 9.64688 8.0021 9.64688C7.14516 9.64688 6.31054 9.73915 5.49822 9.92371C4.68605 10.1084 3.82286 10.4235 2.90864 10.869C2.71766 10.9659 2.56107 11.1126 2.43885 11.3093C2.31662 11.5059 2.25551 11.7156 2.25551 11.9383V12.6833ZM8.00003 5.54869C8.56531 5.54869 9.03762 5.35894 9.41697 4.97944C9.79647 4.60009 9.98622 4.12778 9.98622 3.5625C9.98622 2.99722 9.79647 2.52491 9.41697 2.14556C9.03762 1.76606 8.56531 1.57631 8.00003 1.57631C7.43475 1.57631 6.96244 1.76606 6.5831 2.14556C6.2036 2.52491 6.01384 2.99722 6.01384 3.5625C6.01384 4.12778 6.2036 4.60009 6.5831 4.97944C6.96244 5.35894 7.43475 5.54869 8.00003 5.54869Z"
                        fill="#033333"
                      />
                    </svg>
                  </Box>
                  <Box>{myUser.first_name}</Box>
                </button>
                {/* <ButtonPrimary buttonText={myUser.first_name} /> */}
              </Box>
              <Box className="LogoutBtn" onClick={handleLogout}>
                <ButtonPrimary buttonText="Logout" />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {PriceBoxUrl.indexOf(currentRoute) !== -1 && (
        <>
          <PriceBox
            nextUrl={nextUrl}
            updatePriceBox={updatePriceBox}
            updatePageCount={updatePageCount}
            addBtnFunc={addBtnFunc}
            handleSaveProgress={handleSaveProgress}
            CoverData={CoverData}
            isLoggedIn={isLoggedIn}
          />
          
        </>
      )}
      <ToastContainer autoClose={false} draggable={false} />
    </>
  );
}
