import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";

import axios from "axios";
import config from "../../config";
import "./style.css";

import FooterLogo from "../../Assets/images/BYBnewtag(white)-PLANNER.png";

export default function Footer({ extraClass }) {
  const BACKEND_URL = config.BACKEND_URL;

  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [subscribeEmailError, setSubscribeEmailError] = useState("");

  const emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubscribe = () => {
    setSubscribeEmailError("");
    //setSubscribed(false);
    if (emailIsValid(subscribeEmail)) {
      axios
        .post(`${BACKEND_URL}/api/V1/subscribe/`, { subscribeEmail })
        .then((response) => {
          const { message } = response.data.result;
          setSubscribeEmail("");
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      setSubscribeEmailError("Please enter a valid email address.");
    }
  };

  const handleSubcribeEnter = (e) => {
    if (e.key === "Enter") {
      handleSubscribe();
    }
  };

  return (
    <>
      <Box className={`Footer ${extraClass}`}>
        <Box className="footerMainBox">
          <Box className="footerItemBox">
            <Box className="footerItemSubBox">
              <Typography className="poppinsBold">
                Subscribe to our weekly productivity, well-being, and leadership
                newsletter
              </Typography>
              <Box className="subscribe-info">
                {subscribeEmailError && (
                  <Typography className="SubscribeErrorMsg">
                    {subscribeEmailError}
                  </Typography>
                )}
                <Typography>
                  Receive a 12-part series on get the most out of any planner.
                </Typography>
              </Box>
              <Box className="subscribe-email">
                <input
                  className="footerMailInput"
                  placeholder="What’s your email?"
                  value={subscribeEmail}
                  onChange={(e) => {
                    setSubscribeEmail(e.target.value);
                  }}
                  onKeyPress={handleSubcribeEnter}
                />
                <Box className="send-button" onClick={handleSubscribe}>
                  <img
                    className="sendArrowIcon"
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/sendArrowIcon.svg"
                    alt="SendArrowIcon"
                  />
                </Box>
              </Box>
            </Box>
            <Box className="footerInfoBox">
              <Box className="center">
                <Box className="center_left">
                  <Box className="panel">
                    <Box className="centerSubBox">
                      <Link to="/about-us">
                        <Typography>About us</Typography>
                      </Link>
                    </Box>
                    <Box className="centerSubBox">
                      <Link to="/faqs">
                        <Typography>FAQ</Typography>
                      </Link>
                    </Box>
                    <Box className="centerSubBox">
                      <Link to="/contact-us">
                        <Typography>Contact us</Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Box className="panel">
                    <Box className="centerSubBox">
                      <Link to="/shipping-return">
                        <Typography>Shipping / Returns</Typography>
                      </Link>
                    </Box>
                    <Box className="centerSubBox">
                      <Link to="/privacy-policy">
                        <Typography>Privacy policy</Typography>
                      </Link>
                    </Box>
                    <Box className="centerSubBox">
                      <Link to="/terms-conditions">
                        <Typography>Terms of service</Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>

                <Box className="footerCompanyIconBox">
                  <Box className="FooterCompanyIcon">
                    <Link to="https://pin.it/4i8GqiBKj">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/Pinterest.svg"
                        alt="PinterestIcon"
                      />
                    </Link>
                  </Box>
                  <Box className="FooterCompanyIcon">
                    <Link to="https://www.instagram.com/becomingyourbestplanner/">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/Instagram.svg"
                        alt="InstagramIcon"
                      />
                    </Link>
                  </Box>

                  <Box className="FooterCompanyIcon">
                    <Link to="https://www.facebook.com/profile.php?id=61551543479478&mibextid=PtKPJ9">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/Facebook.svg"
                        alt="FacebookIcon"
                      />
                    </Link>
                  </Box>
                  <Box className="FooterCompanyIcon">
                    <Link to="https://www.tiktok.com/@becomingyourbestplanner">
                      <img
                        src="https://byb-svg.s3.us-west-2.amazonaws.com/Linkedin.svg"
                        alt="Linkedin"
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box className="footerItemBoxLogo">
                <Box className="companyLogoBox">
                  <Box className="footerBottomBox" sx={{ opacity: "70%" }}>
                    <Typography>
                      Copyright, Becoming Your Best Global Leadership
                    </Typography>
                  </Box>
                  <Link to="https://becomingyourbestplanner.com">
                    <img
                      className="FooterLogo"
                      src={FooterLogo}
                      alt="FooterLogo"
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <ToastContainer autoClose={false} draggable={false} />
    </>
  );
}
