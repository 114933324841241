import React, { useState, useEffect, useRef } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./style.css";
import axios from "axios";
import config from "../../config";
import { ToastContainer } from "react-toastify";

//component
import NavBar from "../NavBar";
import Footer from "../Footer";
import { ButtonPrimary } from "../../Components/Buttons";
import CartItem from "../../Components/CartItem";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../PaymentForm";
import BasicModal from "../../Components/Modal/BasicModal";
import StoriesSliderForCart from "../../Components/StoriesSliderForCart";

export default function Cart() {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate();

  const userSession = localStorage.getItem("User");
  const apiToken = localStorage.getItem("Token");
  const ApiHeaders = {
    Authorization: `Bearer ${apiToken}`,
    "Content-Type": "application/json", // Include this header if needed
  };

  const [myUser, setMyUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [showAddress, setShowAddress] = useState(false);

  const [coupon, setCoupon] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [stateDrop, setStateDrop] = useState(false);
  const [stateDropVal, setStateDropVal] = useState("");
  const [countryDrop, setCountryDrop] = useState(false);
  const [countryDropVal, setCountryDropVal] = useState("United States");
  const [userPassword, setUserPassword] = useState("");
  const [createAccount, setCreateAccount] = useState(false);
  const [cartID, setCartID] = useState("");
  const [cartData, setCartData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartCoupon, setCartCoupon] = useState("");
  const [discountAmt, setDiscountAmt] = useState(0);
  const [tax, setTax] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [houseNoError, setHouseNoError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [stateDropValError, setStateDropValError] = useState(false);
  const [countryDropValError, setCountryDropValError] = useState(false);
  const [userPasswordError, setUserPasswordError] = useState(false);
  const [registerPassV, setRegisterPassV] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [stroriesData, setStroriesData] = useState([]);
  const [itemsQty, setItemsQty] = useState([]);
  const [updateQty, setUpdateQty] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [open, setOpen] = useState();
  const [userStatus, setUserStatus] = useState("");
  const firstDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isFrozen, setIsFrozen] = useState(false);
  const paymentDivRef = useRef(null);

  const [shippingChecked, setShippingChecked] = useState(false);
  const [shippingHouseNo, setShippingHouseNo] = useState("");
  const [shippingHouseNoError, setShippingHouseNoError] = useState(false);
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCityError, setShippingCityError] = useState(false);

  const [shippingCountryDrop, setShippingCountryDrop] = useState(false);
  const [shippingCountryDropVal, setShippingCountryDropVal] =
    useState("United States");
  const [shippingCountryDropValError, setShippingCountryDropValError] =
    useState(false);

  const [shippingStreet, setShippingstreet] = useState("");

  const [shippingStateDrop, setShippingStateDrop] = useState(false);
  const [shippingStateDropVal, setShippingStateDropVal] = useState("");
  const [shippingStateDropValError, setShippingStateDropValError] =
    useState("");
  const [shippingZipcode, setShippingZipcode] = useState("");

  const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY, {
    locale: "en",
  });

  const onClose = () => {
    setOpen(false);
  };

  const handleFreezeAndScrollToPayment = () => {
    if (
      email === "" ||
      name === "" ||
      houseNo === "" ||
      city === "" ||
      stateDropVal === "" ||
      zipcode === "" ||
      countryDropVal === "" ||
      (shippingChecked && shippingZipcode === "") ||
      (shippingChecked && shippingHouseNo === "") ||
      (shippingChecked && shippingCity === "") ||
      (shippingChecked && shippingCountryDropVal === "") ||
      (shippingChecked && shippingStateDropVal === "")
    ) {
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      setIsValid(true);
      if (name === "") setNameError(true);
      else setNameError(false);
      if (email === "") setEmailError(true);
      else if (email !== "" && !emailRegex.test(email)) setEmailError(true);
      else setEmailError(false);
      if (houseNo === "") setHouseNoError(true);
      else setHouseNoError(false);
      if (shippingChecked && shippingHouseNo === "")
        setShippingHouseNoError(true);
      else setShippingHouseNoError(false);
      if (city === "") setCityError(true);
      else setCityError(false);
      if (shippingChecked && shippingCity === "") setShippingCityError(true);
      else setShippingCityError(false);
      if (stateDropVal === "") setStateDropValError(true);
      else setStateDropValError(false);
      if (zipcode === "") setZipcodeError(true);
      else setZipcodeError(false);
      if (countryDropVal === "") setCountryDropValError(true);
      else setCountryDropValError(false);
      if (shippingChecked && shippingCountryDropVal === "")
        setShippingCountryDropValError(true);
      else setShippingCountryDropValError(false);
      if (shippingChecked && shippingStateDropVal === "")
        setShippingStateDropValError(true);
      else setShippingStateDropValError(false);
      if (shippingChecked && shippingZipcode === "")
        setShippingStateDropValError(true);
      else setShippingStateDropValError(false);
      return true;
    } else {
      if (!shippingChecked) {
        setShippingHouseNo(houseNo);
        setShippingstreet(street);
        setShippingCity(city);
        setShippingStateDropVal(stateDropVal);
        setShippingZipcode(zipcode);
        setCountryDropVal(countryDropVal);
      }
      if (isChecked) {
        setIsFrozen(true);
        if (paymentDivRef.current) {
          paymentDivRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      firstDropdownRef.current &&
      !firstDropdownRef.current.contains(event.target)
    ) {
      setStateDrop(false);
      setShippingStateDrop(false);
    }
    if (
      secondDropdownRef.current &&
      !secondDropdownRef.current.contains(event.target)
    ) {
      setCountryDrop(false);
      setShippingCountryDrop(false);
    }
  };

  const addShippingDetails = () => {
    setShippingChecked(!shippingChecked);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isFrozen) {
      axios
        .post(`${BACKEND_URL}/api/V1/create-secret`, { cartTotal })
        .then(async (r) => {
          setClientSecret(r.data.clientSecret);
        })
        .catch((error) => {
          console.error("Error fetching slider data:", error);
        });
    }
  }, [isFrozen]);

  useEffect(() => {
    var userSessionData = "";
    if (userSession !== undefined && userSession !== null) {
      userSessionData = JSON.parse(userSession);
      setMyUser(userSessionData);
      setIsLoggedIn(true);
      setOpen(false);
    } else {
      setOpen(true);
    }
    axios
      .get(`${BACKEND_URL}/api/V1/home`)
      .then((response) => {
        setStroriesData(response.data.result.stories);
      })
      .catch((error) => {
        console.error("Error fetching slider data:", error);
      });
    var CartSessionData = JSON.parse(localStorage.getItem("Cart"));
    if (CartSessionData !== undefined && CartSessionData !== null) {
      setCartID(CartSessionData.CartID);
      axios
        .get(`${BACKEND_URL}/api/V1/getCart/${CartSessionData.CartID}`, {
          headers: ApiHeaders,
        })
        .then((response) => {
          const { Cart, CartItems } = response.data.result;
          if (CartItems.length === 0) {
            navigate("/");
          } else {
            setCartData(Cart);
            setCartItems(CartItems);
            const filteredItems = CartItems.map(({ id, quantity }) => ({
              id,
              quantity,
            }));
            setItemsQty(filteredItems);

            const totalQuantity = CartItems.reduce(
              (total, item) => total + item.quantity,
              0
            );
            PinterestTrackCode(Cart.total, totalQuantity, "USD");
            //TiktokTrackCode(isLoggedIn, myUser, Cart, CartItems,'USD');
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else if (userSession !== undefined && userSession !== null) {
      var userSessionData = JSON.parse(userSession);

      axios
        .get(`${BACKEND_URL}/api/V1/getCart/?user_id=${userSessionData.id}`, {
          headers: ApiHeaders,
        })
        .then((response) => {
          const { Cart, CartItems } = response.data.result;
          setCartID(Cart.id);
          setCartData(Cart);
          setCartItems(CartItems);
          const filteredItems = CartItems.map(({ id, quantity }) => ({
            id,
            quantity,
          }));
          setItemsQty(filteredItems);

          var cart_data = { CartID: Cart.id };
          localStorage.setItem("Cart", JSON.stringify(cart_data));
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    }
  }, []);

  const PinterestTrackCode = (total, quantity, currency) => {
    // Pinterest tracking code
    window.pintrk("track", "addtocart", {
      event_id: "eventId0001",
      value: total,
      order_quantity: quantity,
      currency: currency,
    });
  };

  /*const TiktokTrackCode = (isLoggedIn, myUser, Cart, CartItems,currency)=>{
    // Tiktok tracking code
    if(isLoggedIn){
      var hashedEmail = window.sha256(myUser.email);
      var hashedPhone = window.sha256(myUser.phone);
      var hashedId = window.sha256(Cart.id);
      window.ttq.identify({
        "email": hashedEmail, // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
        "phone_number": hashedPhone, // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
        "external_id": hashedId // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
      })
    }else{
      var hashedId = window.sha256(Cart.id);
      window.ttq.identify({
        "external_id": hashedId // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
      })
    }

    const contents = CartItems.map(item => {
      return {
        "content_id": item.id.toString(),
        "content_type": item.type.toString(),
        "content_name": item.title.toString(),
      };
    });

    window.ttq.track('AddToCart', {
      "value": Cart.total, // number. Value of the order or items sold. Example: 100.
      "currency": `${currency}`, // string. The 4217 currency code. Example: "USD".
      "contents": contents
    });
  }*/

  const handleCheckout = async () => {
    if (isChecked) {
      axios
        .post(
          `${BACKEND_URL}/api/V1/updateCart/`,
          {
            CartID: cartID,
            ItemsQty: JSON.stringify(itemsQty),
          },
          { headers: ApiHeaders }
        )
        .then((response) => {
          if (response.data.status) {
            const { Cart, CartItems, message } = response.data.result;
            setCartData(Cart);
            setCartItems(CartItems);
            updateQty ? console.log("") : navigate("/cart");
          } else {
            console.error("Sorry! unable to checkout.");
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      console.error("Please accept the terms.");
    }
  };

  const handleDeleteItem = (item_id, showAlert = true) => {
    axios
      .post(
        `${BACKEND_URL}/api/V1/deleteCartItem`,
        {
          CartID: cartID,
          ItemId: item_id,
        },
        { headers: ApiHeaders }
      )
      .then((response) => {
        const { Cart, CartItems, message } = response.data.result;
        setCartData(Cart);
        setCartItems(CartItems);
        const filteredItems = CartItems.map(({ id, quantity }) => ({
          id,
          quantity,
        }));
        setItemsQty(filteredItems);
        if (showAlert) {
          console.log("message", message);
        }
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  };

  const CountryList = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegowina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, the Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote d'Ivoire",
    "Croatia (Hrvatska)",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "France Metropolitan",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and Mc Donald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao, People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia (Slovak Republic)",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "St. Helena",
    "St. Pierre and Miquelon",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen Islands",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna Islands",
    "Western Sahara",
    "Yemen",
    "Yugoslavia",
    "Zambia",
    "Zimbabwe",
  ];
  const StateList = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {
    var userSessionData = "";
    if (userSession !== undefined && userSession !== null) {
      userSessionData = JSON.parse(userSession);
      setMyUser(userSessionData);
      setIsLoggedIn(true);
    }

    var CartSessionData = JSON.parse(localStorage.getItem("Cart"));
    if (CartSessionData !== undefined && CartSessionData !== null) {
      setCartID(CartSessionData.id);
      axios
        .get(
          `${BACKEND_URL}/api/V1/getCart/${CartSessionData.CartID}?user_id=${userSessionData?.id}`,
          { headers: ApiHeaders }
        )
        .then((response) => {
          const { Cart, CartItems, AddressData } = response.data.result;
          setCartData(Cart);
          setCartItems(CartItems);
          setAddressData(AddressData);
          setCartID(Cart.id);
          setCartSubTotal(Cart.subtotal);
          setCartCoupon(Cart.coupon);
          setDiscountAmt(Cart.discount_amount);
          setTax(Cart.tax);
          setShippingCost(Cart.shipping_cost);
          setCartTotal(Cart.total);

          if (Cart.shipping_name !== "" && Cart.shipping_name != null) {
            setName(Cart.shipping_name || "");
            setEmail(Cart.shipping_email || "");
            setPhone(Cart.shipping_phone || "");
            setHouseNo(Cart.billing_houseno || "");
            setStreet(Cart.billing_street || "");
            setCity(Cart.billing_city || "");
            setZipcode(Cart.billing_zipcode || "");
            setStateDropVal(Cart.billing_state || "");
            setCountryDropVal(Cart.billing_country || "");

            setShippingHouseNo(Cart.shipping_houseno || "");
            setShippingstreet(Cart.shipping_street || "");
            setShippingCity(Cart.shipping_city || "");
            setShippingZipcode(Cart.shipping_zipcode || "");
            setShippingStateDropVal(Cart.shipping_state || "");
            setShippingCountryDropVal(Cart.shipping_country || "");
          }

          const totalQuantity = CartItems.reduce(
            (total, item) => total + item.quantity,
            0
          );
          PinterestTrackCode(Cart.id, Cart.total, totalQuantity, "USD");

          //TiktokTrackCode(isLoggedIn, myUser, Cart, CartItems, 'USD');
          //PinterestEventAPI(config, myUser, Cart, CartItems);
          //TiktokEventAPI(config, myUser, Cart, CartItems);
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {}, [cartTotal]);

  const handleSaveShippingInfo = async (type) => {
    if (
      name === "" ||
      email === "" ||
      houseNo === "" ||
      city === "" ||
      stateDropVal === "" ||
      zipcode === "" ||
      countryDropVal === "" ||
      shippingZipcode === "" ||
      (type === "AccountCheckout" && userPassword === "") ||
      (shippingChecked && shippingHouseNo === "") ||
      (shippingChecked && shippingCity === "") ||
      (shippingChecked && shippingCountryDropVal === "") ||
      (shippingChecked && shippingStateDropVal === "")
    ) {
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      setIsValid(true);
      if (name === "") setNameError(true);
      else setNameError(false);
      if (email === "") setEmailError(true);
      else if (email !== "" && !emailRegex.test(email)) setEmailError(true);
      else setEmailError(false);
      if (houseNo === "") setHouseNoError(true);
      else setHouseNoError(false);
      if (shippingChecked && shippingHouseNo === "")
        setShippingHouseNoError(true);
      else setShippingHouseNoError(false);
      if (city === "") setCityError(true);
      else setCityError(false);
      if (shippingChecked && shippingCity === "") setShippingCityError(true);
      else setShippingCityError(false);
      if (stateDropVal === "") setStateDropValError(true);
      else setStateDropValError(false);
      if (zipcode === "") setZipcodeError(true);
      else setZipcodeError(false);
      if (countryDropVal === "") setCountryDropValError(true);
      else setCountryDropValError(false);
      if (shippingChecked && shippingCountryDropVal === "")
        setShippingCountryDropValError(true);
      else setShippingCountryDropValError(false);

      if (type === "AccountCheckout" && userPassword === "")
        setUserPasswordError(true);
      else setUserPasswordError(false);
      if (shippingStateDropVal === "") setShippingStateDropValError(true);
      else setShippingStateDropValError(false);
      if (shippingZipcode === "") setShippingStateDropValError(true);
      else setShippingStateDropValError(false);
      return true;
    } else {
      setIsValid(false);

      const postData = {
        CartID: cartID,
        shipping_name: name,
        shipping_email: email,
        shipping_phone: phone,
        shipping_houseno: shippingHouseNo,
        shipping_street: shippingStreet,
        shipping_city: shippingCity,
        shipping_state: shippingStateDropVal,
        shipping_zipcode: shippingZipcode,
        shipping_country: shippingCountryDropVal,
        billing_houseno: houseNo,
        billing_street: street,
        billing_city: city,
        billing_state: stateDropVal,
        billing_zipcode: zipcode,
        billing_country: countryDropVal,
        sub_total: cartSubTotal,
        coupon: cartCoupon,
        discount_amount: discountAmt,
        tax: tax,
        shipping_cost: shippingCost,
        total: cartTotal,
        userPassword: type === "AccountCheckout" ? userPassword : "",
        user_id: myUser.id,
      };
      localStorage.setItem("Cart", JSON.stringify(postData));
      axios
        .post(`${BACKEND_URL}/api/V1/updateShippingInfo`, postData, {
          headers: ApiHeaders,
        })
        .then((response) => {
          if (response.data.status) {
            const { Cart, CartItems, message, token, user } =
              response.data.result;
            setCartData(Cart);
            if (token && user) {
              localStorage.setItem("User", JSON.stringify(user));
              localStorage.setItem("Token", token);
            }
            if (Cart.total === 0) {
              ZeroPayment();
            }
            if (Cart.total > 0) {
              navigate("/cart");
            }
          } else {
            console.error(response.data.errors);
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });

      return false;
    }
  };

  const ZeroPayment = () => {
    if (cartTotal === 0) {
      axios
        .post(
          `${BACKEND_URL}/api/V1/process-payment`,
          {
            CartID: cartID,
          },
          { headers: ApiHeaders }
        )
        .then((response) => {
          navigate("/order-success");
        })
        .catch((error) => {
          navigate("/payment-failed");
          console.error("Error fetching layout data:", error);
        });
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(price);
  };

  const handleCountry = (e) => {
    setCountryDrop(false);
    setShippingCountryDrop(false);
    setCountryDropVal(e.target.innerText);
    if (!shippingChecked) {
      var shipAmount = 0;
      if (e.target.innerText === "Canada" || e.target.innerText === "Mexico") {
        shipAmount = 20;
      } else if (e.target.innerText === "United States") {
        shipAmount = 0;
      } else if (
        e.target.innerText !== "" &&
        e.target.innerText !== "United States" &&
        e.target.innerText !== "Canada" &&
        e.target.innerText !== "Mexico"
      ) {
        shipAmount = 50;
      }

      setShippingCost(shipAmount);
      setCartTotal(cartSubTotal + tax + shipAmount - discountAmt);
    }
  };

  const handleShippingCountry = (e) => {
    console.log("e.target.innerText", e.target.innerText);

    setCountryDrop(false);
    setShippingCountryDrop(false);
    setShippingCountryDropVal(e.target.innerText);
    var shipAmount = 0;
    if (e.target.innerText === "Canada" || e.target.innerText === "Mexico") {
      shipAmount = 20;
    } else if (e.target.innerText === "United States") {
      shipAmount = 0;
    } else if (
      e.target.innerText !== "" &&
      e.target.innerText !== "United States" &&
      e.target.innerText !== "Canada" &&
      e.target.innerText !== "Mexico"
    ) {
      shipAmount = 50;
    }

    setShippingCost(shipAmount);
    setCartTotal(cartSubTotal + tax + shipAmount - discountAmt);
  };

  const applyCoupon = () => {
    if (!isLoggedIn && email === "") {
      console.error("Please enter the email first");
    } else {
      axios
        .post(
          `${BACKEND_URL}/api/V1/checkCoupon`,
          {
            CartID: cartID,
            Coupon: coupon,
            UserId: myUser?.id,
            shipping_email: email || "",
          },
          { headers: ApiHeaders }
        )
        .then((response) => {
          if (response.data.status) {
            const { Cart, message } = response.data.result;
            setCartData(Cart);

            setCartSubTotal(Cart.subtotal);
            setCartCoupon(Cart.coupon);
            setDiscountAmt(Cart.discount_amount);
            setTax(Cart.tax);
            setShippingCost(Cart.shipping_cost);
            setCartTotal(Cart.total);

            setCoupon("");
            console.log(message);
          } else {
            console.error(response.data.errors);
          }
        })
        .catch((error) => {
          console.error("Error fetching layout data:", error);
        });
    }
  };

  const removeCoupon = () => {
    axios
      .post(
        `${BACKEND_URL}/api/V1/removeCoupon`,
        {
          CartID: cartID,
          UserId: myUser?.id,
        },
        { headers: ApiHeaders }
      )
      .then((response) => {
        const { Cart, message } = response.data.result;
        setCartData(Cart);

        setCartSubTotal(Cart.subtotal);
        setCartCoupon(Cart.coupon);
        setDiscountAmt(Cart.discount_amount);
        setTax(Cart.tax);
        setShippingCost(Cart.shipping_cost);
        setCartTotal(Cart.total);
        console.log(message);
      })
      .catch((error) => {
        console.error("Error fetching layout data:", error);
      });
  };

  const selectAddress = (addressId) => {
    const address = addressData.find((item) => item.id === addressId);
    setHouseNo(address.houseno);
    setStreet(address.street);
    setCity(address.city);
    setStateDropVal(address.state);
    setZipcode(address.zipcode);
    setCountryDropVal(address.country);
    setShowAddress(false);
  };

  const callFuncion = async () => {
    let check = false;
    if (isChecked) {
      await handleCheckout();
      await handleSaveShippingInfo(userStatus).then((res) => {
        check = res;
      });
      return check;
    }
  };

  const updateUserStatrus = (type) => {
    setUserStatus(type);
  };

  const handleItemQty = (id, newQty) => {
    let sum = 0;

    const updatedItemsQty = itemsQty.map((item) =>
      item.id === id ? { ...item, quantity: newQty } : item
    );

    for (let i = 0; i < cartItems.length; i++) {
      for (let j = 0; j < updatedItemsQty.length; j++) {
        if (cartItems[i].id === updatedItemsQty[j].id) {
          sum += Number(cartItems[i].price * updatedItemsQty[j].quantity);
        }
      }
    }

    setItemsQty(updatedItemsQty);
    setUpdateQty(true);
    setCartSubTotal(sum);
    setCartTotal(sum + tax + shippingCost - discountAmt);
  };

  useEffect(() => {
    let sum = 0;
    for (let i = 0; i < cartItems.length; i++) {
      for (let j = 0; j < itemsQty.length; j++) {
        if (cartItems[i].id === itemsQty[j].id) {
          sum += Number(cartItems[i].price * itemsQty[j].quantity);
        }
      }
    }

    console.log("sum", cartItems);
    setCartSubTotal(sum);

    setCartTotal(sum + tax + shippingCost - discountAmt);
  }, [cartItems]);

  return (
    <Box className="cartMain">
      <NavBar updateNavBar={itemsQty} />
      <BasicModal
        open={open}
        onClose={onClose}
        updateUserStatrus={updateUserStatrus}
      />
      <Box className="main">
        <Box className="checkoutHeader">
          <Typography className="cartHeader">Checkout</Typography>
        </Box>
        <Box className="cartContainer">
          <Box className="right_box">
            <Box className="shippingInfoContainer">
              <Box className="right_box_container">
                <Box className="cartBox">
                  {isLoggedIn && addressData.length > 0 && (
                    <Box>
                      <Box className="addressRow">
                        <Typography className="cartHeaderSubtotal">
                          Address Book
                        </Typography>
                        <Typography
                          className="addressTabLinkText pointer"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          {!showAddress ? "Expand" : "Hide"}
                        </Typography>
                      </Box>
                      <Box
                        className="AddressList"
                        sx={{ display: showAddress ? "show" : "none" }}
                      >
                        {addressData.map((address, index) => (
                          <Box className="addressRow" key={address.id}>
                            <Typography>{index + 1}.</Typography>
                            <Typography>
                              {address.houseno}{" "}
                              {address.street && address.street + ", "}{" "}
                              {address.city} {address.state}, {address.country}{" "}
                              {address.zipcode && " - " + address.zipcode}
                            </Typography>
                            <Typography
                              className="addressTabLinkText"
                              onClick={() => selectAddress(address.id)}
                            >
                              Select
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  )}
                  <Box
                    className="cartItemBox"
                    sx={{
                      pointerEvents: isFrozen ? "none" : "auto",
                      opacity: isFrozen ? 0.5 : 1,
                    }}
                  >
                    <Typography className="sectionHeader">
                      Customer Information
                    </Typography>
                    <Box mb={1.5} className="shipInputBox">
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">Email *</Typography>
                        <TextField
                          className={`stateInput ${
                            emailError ? "required" : ""
                          }`}
                          placeholder="email@email.com"
                          tabIndex="3"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Box mb={1.5} className="shipInputBox">
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">Name *</Typography>

                        <TextField
                          className={`Ffour ${nameError ? "required" : ""}`}
                          placeholder="name"
                          tabIndex="1"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">Phone</Typography>
                        <TextField
                          className="Ffour"
                          placeholder="Phone"
                          tabIndex="2"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>

                    <Typography className="sectionHeader">
                      Billing Details
                    </Typography>
                    <Box mb={1.5} className="shipInputBox">
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">
                          Street address *
                        </Typography>
                        <TextField
                          className={`Ffour ${houseNoError ? "required" : ""}`}
                          placeholder="Street"
                          tabIndex="4"
                          value={houseNo}
                          onChange={(e) => {
                            setHouseNo(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">
                          Town / City *
                        </Typography>

                        <TextField
                          className={`Ffour ${cityError ? "required" : ""}`}
                          placeholder="City"
                          tabIndex="6"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box mb={1.5} className="shipInputBox">
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">
                          Country/Region *
                        </Typography>
                        <TextField
                          className={`stateInput ${
                            countryDropValError ? "required" : ""
                          }`}
                          onClick={() => {
                            setStateDrop(false);
                            setCountryDrop(!countryDrop);
                          }}
                          placeholder="Country"
                          tabIndex="10"
                          type="dropDown"
                          value={countryDropVal}
                        />
                        <ArrowDropDownIcon
                          onClick={() => setCountryDrop(!countryDrop)}
                          className="StateDropArrow"
                        />
                        <Box
                          sx={{ height: countryDrop ? "auto" : "0px" }}
                          className="stateDrop"
                          tabIndex="11"
                          //   ref={secondDropdownRef}
                        >
                          {CountryList.map((country, index) => (
                            <Box
                              key={index}
                              onClick={handleCountry}
                              className="stateDropItem"
                            >
                              <Typography>{country}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box className="state_box">
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            State *
                          </Typography>
                          {countryDropVal !== "United States" && (
                            <TextField
                              className={`stateInput ${
                                stateDropValError ? "required" : ""
                              }`}
                              placeholder="State"
                              tabIndex="7"
                              value={stateDropVal}
                              onChange={(e) => {
                                setStateDropVal(e.target.value);
                              }}
                            />
                          )}
                          {countryDropVal === "United States" && (
                            <TextField
                              className={`cityInput ${
                                stateDropValError ? "required" : ""
                              }`}
                              placeholder="State"
                              tabIndex="7"
                              value={stateDropVal}
                              onClick={() => {
                                setStateDrop(!stateDrop);
                              }}
                            />
                          )}
                          {countryDropVal === "United States" && (
                            <ArrowDropDownIcon
                              onClick={() => setStateDrop(!stateDrop)}
                              className="cityDropArrow"
                            />
                          )}
                          {countryDropVal === "United States" && (
                            <Box
                              sx={{ height: stateDrop ? "auto" : "0px" }}
                              className="stateDrop"
                              ref={firstDropdownRef}
                            >
                              {StateList.map((state, index) => (
                                <Box
                                  key={index}
                                  onClick={(e) => {
                                    setStateDropVal(e.target.innerText);
                                  }}
                                  className="stateDropItem"
                                >
                                  <Typography>{state}</Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box mb={1.5} className="shipInputBox">
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">
                          Ste/apt/etc *
                        </Typography>
                        <TextField
                          className="Ffour"
                          placeholder="Ste, apt, etc"
                          tabIndex="5"
                          value={street}
                          onChange={(e) => {
                            setStreet(e.target.value);
                          }}
                        />
                      </Box>
                      <Box className="shipInputBox_ct">
                        <Typography className="input_label">
                          Postcode *
                        </Typography>
                        <TextField
                          className={`cityInput ${
                            zipcodeError ? "required" : ""
                          }`}
                          placeholder="90001"
                          tabIndex="9"
                          value={zipcode}
                          onChange={(e) => {
                            setZipcode(e.target.value);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="addressCheck"
                    sx={{
                      pointerEvents: isFrozen ? "none" : "auto",
                      opacity: isFrozen ? 0.5 : 1,
                    }}
                  >
                    <input
                      onClick={() => addShippingDetails()}
                      type="checkbox"
                    />
                    <Typography ml={1}>
                      Shipping to a different address?
                    </Typography>
                  </Box>

                  {shippingChecked && (
                    <Box
                      className="cartItemBox"
                      sx={{
                        pointerEvents: isFrozen ? "none" : "auto",
                        opacity: isFrozen ? 0.5 : 1,
                      }}
                    >
                      <Typography className="sectionHeader">
                        Shipping Details
                      </Typography>
                      <Box mb={1.5} className="shipInputBox">
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            Street address *
                          </Typography>
                          <TextField
                            className={`Ffour ${
                              shippingHouseNoError ? "required" : ""
                            }`}
                            placeholder="Street"
                            tabIndex="4"
                            value={shippingHouseNo}
                            onChange={(e) => {
                              setShippingHouseNo(e.target.value);
                            }}
                          />
                        </Box>
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            Town / City *
                          </Typography>

                          <TextField
                            className={`Ffour ${
                              shippingCityError ? "required" : ""
                            }`}
                            placeholder="City"
                            tabIndex="6"
                            value={shippingCity}
                            onChange={(e) => {
                              setShippingCity(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box mb={1.5} className="shipInputBox">
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            Country/Region *
                          </Typography>
                          <TextField
                            className={`stateInput ${
                              shippingCountryDropValError ? "required" : ""
                            }`}
                            onClick={() => {
                              setShippingStateDrop(false);
                              setShippingCountryDrop(!shippingCountryDrop);
                            }}
                            placeholder="Country"
                            tabIndex="10"
                            type="dropDown"
                            value={shippingCountryDropVal}
                          />
                          <ArrowDropDownIcon
                            onClick={() =>
                              setShippingCountryDrop(!shippingCountryDrop)
                            }
                            className="StateDropArrow"
                          />
                          <Box
                            sx={{
                              height: shippingCountryDrop ? "auto" : "0px",
                            }}
                            className="stateDrop"
                            tabIndex="11"
                            // ref={secondDropdownRef}
                          >
                            {CountryList.map((country, index) => (
                              <Box
                                key={index}
                                onClick={handleShippingCountry}
                                className="stateDropItem"
                              >
                                <Typography>{country}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box className="state_box">
                          <Box className="shipInputBox_ct">
                            <Typography className="input_label">
                              State *
                            </Typography>
                            {shippingCountryDropVal !== "United States" && (
                              <TextField
                                className={`stateInput ${
                                  shippingStateDropValError ? "required" : ""
                                }`}
                                placeholder="State"
                                tabIndex="7"
                                value={shippingStateDropVal}
                                onChange={(e) => {
                                  setShippingStateDropVal(e.target.value);
                                }}
                              />
                            )}
                            {shippingCountryDropVal === "United States" && (
                              <TextField
                                className={`cityInput ${
                                  shippingStateDropValError ? "required" : ""
                                }`}
                                placeholder="State"
                                tabIndex="7"
                                value={shippingStateDropVal}
                                onClick={() => {
                                  setShippingStateDrop(!shippingStateDrop);
                                }}
                              />
                            )}
                            {shippingCountryDropVal === "United States" && (
                              <ArrowDropDownIcon
                                onClick={() =>
                                  setShippingStateDrop(!shippingStateDrop)
                                }
                                className="cityDropArrow"
                              />
                            )}
                            {shippingCountryDropVal === "United States" && (
                              <Box
                                sx={{
                                  height: shippingStateDrop ? "auto" : "0px",
                                }}
                                className="stateDrop"
                                ref={firstDropdownRef}
                              >
                                {StateList.map((state, index) => (
                                  <Box
                                    key={index}
                                    onClick={(e) => {
                                      setShippingStateDropVal(
                                        e.target.innerText
                                      );
                                    }}
                                    className="stateDropItem"
                                  >
                                    <Typography>{state}</Typography>
                                  </Box>
                                ))}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box mb={1.5} className="shipInputBox">
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            Ste/apt/etc *
                          </Typography>
                          <TextField
                            className="Ffour"
                            placeholder="Ste, apt, etc"
                            tabIndex="5"
                            value={shippingStreet}
                            onChange={(e) => {
                              setShippingstreet(e.target.value);
                            }}
                          />
                        </Box>
                        <Box className="shipInputBox_ct">
                          <Typography className="input_label">
                            Postcode *
                          </Typography>
                          <TextField
                            className={`cityInput ${
                              zipcodeError ? "required" : ""
                            }`}
                            placeholder="90001"
                            tabIndex="9"
                            value={shippingZipcode}
                            onChange={(e) => {
                              setShippingZipcode(e.target.value);
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {!isChecked ? (
                    <Typography className="ErrMsg">
                      Please accept the terms.
                    </Typography>
                  ) : (
                    ""
                  )}
                  {!isFrozen && (
                    <ButtonPrimary
                      buttonText="Pay"
                      handelClick={handleFreezeAndScrollToPayment}
                    ></ButtonPrimary>
                  )}

                  <Box className="payment_container" ref={paymentDivRef}>
                    {stripePromise && clientSecret && isFrozen && (
                      <Elements
                        stripe={stripePromise}
                        options={{ clientSecret }}
                      >
                        <PaymentForm
                          callFuncion={callFuncion}
                          newCartSubTotal={cartSubTotal}
                          shipAmount={shippingCost}
                          newTax={tax}
                          handleDeleteItem={handleDeleteItem}
                          isChecked={isChecked}
                          isValid={isValid}
                          email={email}
                        />
                      </Elements>
                    )}
                  </Box>
                </Box>
              </Box>
              <ToastContainer autoClose={false} draggable={false} />
            </Box>
          </Box>
          <Box
            className="cartBoxOuter"
            sx={{
              pointerEvents: isFrozen ? "none" : "auto",
              opacity: isFrozen ? 0.5 : 1,
            }}
          >
            <Box className="cartBoxOrder">
              <Box className="orderHeader">
                <Typography className="order_title">Your Order</Typography>
                <Typography
                  sx={{
                    color: "#688585",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "18.9px",
                  }}
                >
                  {cartItems.length} items
                </Typography>
              </Box>
              <Box className="cartItemBox">
                {cartItems.map((item, index) => (
                  <CartItem
                    itemData={item}
                    key={`${index}`}
                    setItemQty={handleItemQty}
                    deleteItem={handleDeleteItem}
                  />
                ))}
              </Box>
              <Box className="paymentCardSummeryBox">
                <Box className="paymentSummeryItem">
                  <Typography className="paymentSummeryText">
                    SUBTOTAL
                  </Typography>
                  <Typography className="paymentSummeryTextPrice">
                    {formatPrice(cartSubTotal)}
                  </Typography>
                </Box>
                {cartCoupon && discountAmt > 0 && (
                  <Box className="paymentSummeryItem">
                    <Typography className="paymentSummeryText">
                      Discount(Coupon: {cartCoupon})
                    </Typography>
                    <Typography className="paymentSummeryTextPrice">
                      {formatPrice(discountAmt)}
                    </Typography>
                  </Box>
                )}
                <Box className="paymentSummeryItem">
                  <Typography className="paymentSummeryText">TAX</Typography>
                  <Typography className="paymentSummeryTextPrice">
                    {formatPrice(tax)}
                  </Typography>
                </Box>
                <Box className="paymentSummeryItem">
                  <Typography className="paymentSummeryText">
                    SHIPPING
                  </Typography>
                  <Typography className="paymentSummeryTextPrice">
                    {formatPrice(shippingCost)}
                  </Typography>
                </Box>
                <Box className="paymentSummeryItem">
                  <Typography className="paymentSummeryTotalText">
                    TOTAL
                  </Typography>
                  <Typography className="paymentSummeryTotalText">
                    {formatPrice(cartTotal)}
                  </Typography>
                </Box>
              </Box>
              <Box className="cartCheckBox">
                <input
                  onClick={() => setIsChecked(!isChecked)}
                  checked={isChecked}
                  type="checkbox"
                />
                <Typography ml={1}>
                  I have reviewed all the items in my cart and understand that
                  my custom items will be printed as shown in the preview.
                </Typography>
              </Box>
              {/* <ButtonPrimary buttonText="CHECKOUT" handelClick={handleCheckout} />  */}
            </Box>
            <StoriesSliderForCart slider={stroriesData} title={false} />
          </Box>
        </Box>
      </Box>

      <Footer />
      <ToastContainer autoClose={false} draggable={false} />
    </Box>
  );
}
