import React from "react";
import "./style.css";
import { Box, Typography } from "@mui/material";
import { ButtonPrimary } from "../../../Components/Buttons";

export default function NewPlanner() {
  return (
    <Box className="planner">
      <Box
        sx={{
          width: "1296px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Box className="planner_header">
          <Box className="planner_header_title">
            <Typography>
              New Planner <span className="title_span"> AND </span> New Skills
            </Typography>
          </Box>
          <Box className="planner_header_subTitle1">
            <Typography>
              Get access to tutorials from top time-management experts!" And
              then these other lines.
            </Typography>
          </Box>
          <Box className="planner_header_subTitle">
            <Typography>
              Learn how to lead a life by design, schedule priorities first,
              prioritize health and relationships, and overcome burnout and task
              saturation!
            </Typography>
          </Box>
        </Box>
        <Box className="planner_content">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "36px",
            }}
          >
            <Box
              sx={{
                maxWidth: "408px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/planner1img.svg"
                alt=""
              />
              <Box className="planner_content_title">
                <Typography>Work-Life Balance</Typography>
              </Box>
              <Box className="planner_content_subTitle">
                <Typography>
                  Mastering Priorities: Why Scheduling What Matters Most
                  Transforms Your Day
                </Typography>
                <span>2 days ago / 5 min read</span>
              </Box>
            </Box>
            <Box
              sx={{
                maxWidth: "408px",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/planner2img.svg"
                alt=""
              />
              <Box className="planner_content_title">
                <Typography>Work-Life Balance</Typography>
              </Box>
              <Box className="planner_content_subTitle">
                <Typography>
                  Life by Design: How to Take Control of Your Time and Live with
                  Purpose
                </Typography>
                <span>2 days ago / 5 min read</span>
              </Box>
            </Box>
          </Box>
          <Box className="palnner_right">
            <Box sx={{ display: "flex", gap: "24px" }}>
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/planner3img.svg"
                alt=""
              />
              <Box
                className="planner_last_container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  The Power of Balance: Prioritizing Health and Relationships
                  for a Fulfilling Life
                </Typography>
                <span>2 days ago / 5 min read</span>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "24px" }}>
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/planner4img.svg"
                alt=""
              />
              <Box
                className="planner_last_container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  Breaking Free from Burnout: Strategies to Reclaim Your Energy
                  and Focus
                </Typography>
                <span>2 days ago / 5 min read</span>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "24px" }}>
              <img
                src="https://byb-svg.s3.us-west-2.amazonaws.com/planner5img.svg"
                alt=""
              />
              <Box
                className="planner_last_container"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  Conquer Task Overload: How to Simplify, Delegate, and Stay on
                  Track
                </Typography>
                <span>2 days ago / 5 min read</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="planner_button">
          <ButtonPrimary
            buttonText="Explore more form our blog"
            width="269px"
          />
        </Box>
      </Box>
    </Box>
  );
}
