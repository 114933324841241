import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
//import { FileDrop } from 'react-file-drop'
import config from "../../config";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../layout.css";
import "./style.css";

import coverPhoto from "../../Assets/images/CoverPhoto.png";
import InsideCoverBinder from "../../Assets/images/insideCoverBinder.png";
import DiaryBaseImage from "../../Assets/images/diary_base.png";

//Component
import NavBar from "../NavBar";
import Footer from "../Footer";

export default function Cover() {
  const location = useLocation();
  const hashValue = location.hash.replace("#", "");

  const [tab, setTab] = useState(hashValue == "FirstPage" ? false : true);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const nextYear = currentYear + 1;

  const [diaryYear, setDiaryYear] = useState(currentYear + "-" + nextYear);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameLoad, setNameLoad] = useState(false);
  const [emailLoad, setEmailLoad] = useState(false);
  const [phoneLoad, setPhoneLoad] = useState(false);
  const [messageLoad, setMessageLoad] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const [priceBox, setPriceBox] = useState(1);
  const [menuPopInfo, setMenuPopInfo] = useState(false);

  useEffect(() => {
    const BACKEND_URL = config.BACKEND_URL;

    if (!localStorage.getItem("coverpopup")) {
      setMenuPopInfo(true);
    }

    //load cover data from session on pageload
    var FirstPageData = localStorage.getItem("FirstPage");
    if (FirstPageData !== null) {
      FirstPageData = JSON.parse(FirstPageData);

      //if (Array.isArray(FirstPageData) && FirstPageData.length > 0) {
      if (FirstPageData !== null) {
        setDiaryYear(FirstPageData?.Year);
        setName(FirstPageData?.Name);
        setEmail(FirstPageData?.Email);
        setPhone(FirstPageData?.Phone);
        setMessage(FirstPageData?.Message);
        setPriceBox(priceBox + 1);
        setNameLoad(true);
        setEmailLoad(true);
        setPhoneLoad(true);
        setMessageLoad(true);
      }
    }

    // Pinterest tracking code
    PinterestTrackCode();
  }, []);

  const PinterestTrackCode = () => {
    // Pinterest tracking code
    if (typeof pintrk === "function") {
      window.pintrk("track", "pagevisit", {
        event_id: "eventId0001",
      });
    }
  };

  const changeFirstPage = (e) => {
    var { name, value } = e.target;

    if (name === "FullName") {
      setName(value);
      setNameLoad(true);
    } else if (name === "Email") {
      setEmail(value);
      setEmailLoad(true);
    } else if (name === "Phone") {
      setPhone(value);
      setPhoneLoad(true);
    } else if (name === "Message") {
      setMessage(value);
      setMessageLoad(true);
    }
  };

  const handleFirstPage = async () => {
    setFormSubmitted(true);
    localStorage.setItem(
      "FirstPage",
      JSON.stringify({
        Year: diaryYear,
        Name: name || "",
        Email: email || "",
        Phone: phone || "",
        Message: message || "",
      })
    );

    setPriceBox(priceBox + 1);

    if (name !== "" || email !== "") {
      axios
        .post(`${config.BACKEND_URL}/api/V1/firstPage`, {
          Name: name || "",
          Email: email || "",
          Phone: phone || "",
        })
        .then((response) => {
          if (response.data.status) {
            console.log(response.data.result.message);
          } else {
            console.log(response.data.errors);
          }
        })
        .catch((error) => {
          console.log("Error sending first page data:", error);
        });
      console.log("FirstPage2=>", localStorage.getItem("FirstPage"));
    }
    window.location.href = "/layout";
  };

  const closePopup = () => {
    localStorage.setItem("coverpopup", "true");
    setMenuPopInfo(false);
  };

  return (
    <>
      <NavBar updatePriceBox={priceBox} addBtnFunc={handleFirstPage} />
      <Box className="PageContainer firstpage">
        <Box className="PageBox">
          <Box className="CoverBox2 PageInnerBox ">
            <Box className="LeftPanelBox noscroll">
              <Box className="LeftPanelInner">
                <Box className="LeftHeader">
                  <Typography className="LeftTitle">
                    Fill in the boxes to see what your first page will look like
                  </Typography>
                  <Typography className="LeftSubText">
                    This will be the first page of your planner and can include
                    your name, email, phone number and a message.
                  </Typography>
                </Box>

                <Box className="step2InputFieldBox">
                  <Box mb={2.3} className="st2InputBox">
                    <input
                      type="text"
                      className="st2FullName"
                      placeholder="Full Name"
                      value={name}
                      name="FullName"
                      onChange={changeFirstPage}
                      required
                    />
                  </Box>
                  <Box className="st2SecondInputBox">
                    <Box sx={{ width: "48%" }} className="st2InputBox">
                      <input
                        type="text"
                        className="st2email"
                        placeholder="Email (Optional)"
                        name="Email"
                        value={email}
                        onChange={changeFirstPage}
                      />
                    </Box>
                    <Box mb={2} sx={{ width: "48%" }} className="st2InputBox">
                      <input
                        type="text"
                        className="st2Phone"
                        placeholder="Phone (Optional)"
                        name="Phone"
                        value={phone}
                        onChange={changeFirstPage}
                      />
                    </Box>
                  </Box>

                  <Box mb={2} className="st2InputBox">
                    <textarea
                      type="text"
                      className="st2Message"
                      name="Message"
                      placeholder="Quote or Personal Message (Optional)"
                      onChange={changeFirstPage}
                      value={message}
                    />
                  </Box>
                </Box>
              </Box>

              <Box
                onClick={handleFirstPage}
                mt={2}
                className="st2InputBtn deskbtn"
              >
                <Typography>Next</Typography>
              </Box>
            </Box>

            <Box className="RightPanelBox">
              <Box mt={3} className="PreviewContainer">
                <img src={DiaryBaseImage} className="diray_base" alt="" />
                <Box className="diray_inner">
                  <Box mr={0.5} className="diray_page st2Page"></Box>
                  <Box ml={0.5} className="diray_page rightpage">
                    <img
                      className="insideCover_binder"
                      src={InsideCoverBinder}
                      alt="InsideCoverBinder"
                    />
                    <Box className="st2InsideCoverBox">
                      <Box className="st2InsideCoverTextBox">
                        <Typography mb={2} className="date">
                          {diaryYear}
                        </Typography>
                        <Typography mb={1} className="nameText">
                          {" "}
                          {nameLoad ? name.toUpperCase() : "Jane Doe"}
                        </Typography>
                        <Typography mb={1} className="contactText">
                          {emailLoad ? email : "support@becomingyourbest.com"}
                        </Typography>
                        <Typography mb={1} className="contactText">
                          {phoneLoad ? phone : "888-888-8888"}
                        </Typography>
                        <Typography mb={1} className="messageText">
                          {messageLoad
                            ? message
                            : "Every day, in every way, I'm getting better and better."}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <ToastContainer autoClose={false} draggable={false} />

      <Footer />
    </>
  );
}
