import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Box, IconButton, Typography } from "@mui/material";

import VideoItems from "./VideoItems";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

export default function VideoComponent() {
  const [isSlider, setIsSlider] = useState();

  const [selectedItem, setSelectedItem] = useState();

  const onSelectItem = (item) => {
    setSelectedItem(item);
  };

  const onCloaseItem = (item) => {
    setSelectedItem(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSlider(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [video] = useState([
    "https://pypvideos.s3.us-west-2.amazonaws.com/044992C56B6E81CC53A8BBF758512180_video_dashinit+(1).mp4",
    "https://pypvideos.s3.us-west-2.amazonaws.com/9346896FFEA87F871F6CF97D5D124B8C_video_dashinit.mp4",
    "https://pypvideos.s3.us-west-2.amazonaws.com/AQOPDD_sDzbHSiNxH6CWahEvpkzG8XisH35vAZrgBKOcJ9CvcRaq4eu0MtFvhiRklyneZxcqIuTd_wVZV-cSqQmM.mp4",
    "https://pypvideos.s3.us-west-2.amazonaws.com/AQOqKlk61azm3oQpRBY9FNfwg2rovToQpO9R5g1SqNHpiOzXewyGo-jYdtEo8MUTAgsFsNa3EnpTc8jc7MoO98eY.mp4",
    "https://pypvideos.s3.us-west-2.amazonaws.com/E740687AC4ABB32B3688924C1F0EF483_video_dashinit.mp4",
  ]);
  const mySwiper2 = useRef();

  const handleNext = () => {
    const next = mySwiper2.current.querySelector(".swiper-button-next");
    next.click();
  };

  const handlePrev = () => {
    const next = mySwiper2.current.querySelector(".swiper-button-prev");
    next.click();
  };

  return (
    <Box className="video_box">
      <Box className="video_container">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className="video_title">
            {isSlider <= 550 ? (
              <Typography>
                Meet{" "}
                <span>
                  #BecomingYourBe <br /> stPlanner
                </span>
              </Typography>
            ) : (
              <Typography>
                Meet <span>#BecomingYourBestPlanner</span>
              </Typography>
            )}
          </Box>
          <Box className="video_subtitle">
            <Typography>
              Learn how others are using their custom planner and new skills!
            </Typography>
          </Box>
        </Box>
        {isSlider > 1400 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "24px",
            }}
          >
            {video.map((elm) => {
              return (
                <Box onClick={() => onSelectItem(elm)}>
                  <VideoItems videoUrl={elm} />
                </Box>
              );
            })}
            {selectedItem && selectedItem !== null && (
              <div className="video_zoom">
                <div className="cancel" onClick={onCloaseItem}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/button.svg"
                    alt=""
                  />
                </div>
                <VideoItems videoUrl={selectedItem} />
              </div>
            )}
          </Box>
        ) : (
          <Box style={{ display: "block", width: "100%", overflow: "hidden" }}>
            <Swiper
              ref={mySwiper2}
              slidesPerView={
                isSlider <= 1400 && isSlider > 1300
                  ? 5
                  : isSlider <= 1300 && isSlider > 1100
                  ? 4.3
                  : isSlider <= 1100 && isSlider > 900
                  ? 3.6
                  : isSlider <= 900 && isSlider > 700
                  ? 2.7
                  : isSlider <= 700 && isSlider > 500
                  ? 2
                  : isSlider <= 500 && isSlider > 0
                  ? 1.5
                  : 0
              }
              centeredSlides={true}
              spaceBetween={10}
              grabCursor={true}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper2"
            >
              {video.map((elm, index) => {
                return (
                  <SwiperSlide key={index} onClick={() => onSelectItem(elm)}>
                    <VideoItems videoUrl={elm} />
                  </SwiperSlide>
                );
              })}
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  zIndex: "10",
                }}
              >
                <IconButton
                  onClick={handlePrev}
                  sx={{
                    color: "white",
                    backgroundColor: "#03333314",
                    "&:hover": { opacity: "70%" },
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-left.svg"
                    alt=""
                  />
                </IconButton>
                <IconButton
                  onClick={handleNext}
                  sx={{
                    color: "white",
                    backgroundColor: "#03333314",
                    "&:hover": { opacity: "70%" },
                  }}
                >
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/chevron-right.svg"
                    alt=""
                  />
                </IconButton>
              </Box>
            </Swiper>
            {selectedItem && selectedItem !== null && (
              <div className="video_zoom">
                <div className="cancel" onClick={onCloaseItem}>
                  <img
                    src="https://byb-svg.s3.us-west-2.amazonaws.com/button.svg"
                    alt=""
                  />
                </div>
                <VideoItems videoUrl={selectedItem} />
              </div>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
